import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import config from "../../config";
import DashboardHeader from "./Header";

const VendorDetailView = () => {
  const { vendorID } = useParams();
  const [vendor, setVendor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [evaluation, setEvaluation] = useState([]);
  const [finalScore, setFinalScore] = useState({});

  useEffect(() => {
    const fetchVendor = async () => {
      try {
        const vendorResponse = await axios.get(
          `${config.BASE_URL}/vendors/${vendorID}`
        );
        setVendor(vendorResponse.data);
      } catch (err) {
        setError("Failed to fetch vendor details.");
      } finally {
        setLoading(false);
      }
    };

    const fetchEvaluation = async () => {
      try {
        const evalResponse = await axios.get(
          `${config.BASE_URL}/review/${vendorID}`
        );
        if (evalResponse.data) {
          setEvaluation(evalResponse.data.evaluations);
          const scores = calculateFinalScores(evalResponse.data.evaluations);
          setFinalScore({
            previousFinalScore: evalResponse.data.previousFinalScore || scores.finalScore,
            recentFinalScore: evalResponse.data.recentFinalScore || scores.finalScore,
          });
        }
      } catch (err) {
        console.error("Failed to fetch evaluation data.");
      }
    };

    fetchVendor();
    fetchEvaluation();
  }, [vendorID]);

  const calculateFinalScores = (evaluationData) => {
    let totalWeightedScore = 0;
  
    // Iterate over categories
    evaluationData.forEach((category) => {
      const categoryMaxPoints = category.details.reduce(
        (sum, detail) => sum + detail.points,
        0
      ); // Total max points in the category
  
      const categoryActualScore = category.details.reduce(
        (sum, detail) => sum + (parseInt(detail.score) || 0),
        0
      ); // Actual score achieved in the category
  
      // Calculate weighted score for the category
      if (categoryMaxPoints > 0) {
        const categoryWeightedScore =
          (categoryActualScore / categoryMaxPoints) * category.weightage;
        totalWeightedScore += categoryWeightedScore;
      }
    });
  
    // Normalize the total weighted score to a scale of 10
    const finalScore = (totalWeightedScore / 100) * 10; // Scale to 10
    return { finalScore: finalScore.toFixed(2) };
  };
  
  
  
  
  

  const getScoreColor = (score) => {
    if (score >= 8) return "text-green-500";
    if (score >= 6) return "text-yellow-500";
    return "text-red-500";
  };

  if (loading) return <div>Loading vendor details...</div>;
  if (error) return <div className="text-red-500">{error}</div>;
  if (!vendor) return <div>No vendor found.</div>;

  return (
    <div className="container mx-auto p-6">
      <DashboardHeader />

      <div className="border p-6 rounded-lg shadow-lg bg-gray-50">
        {/* Vendor Basic Info */}
        <div className="grid grid-cols-2 gap-6">
          <div>
            <p>
              <strong>Vendor ID:</strong> {vendor.vendorID}
            </p>
            <p>
              <strong>Name:</strong> {vendor.name}
            </p>
            <p>
              <strong>Email:</strong> {vendor.email}
            </p>
            <p>
              <strong>Phone:</strong> {vendor.phone}
            </p>
            <p>
              <strong>Vendor Type:</strong> {vendor.vendor_type}
            </p>
          </div>
          <div>
            <p>
              <strong>Status:</strong>
              <span
                className={`ml-2 font-semibold ${
                  vendor.status === "Reviewed"
                    ? "text-green-600"
                    : "text-red-500"
                }`}
              >
                {vendor.status || "Pending"}
              </span>
            </p>
            <p>
              <strong>Classification:</strong> {vendor.classification_type}
            </p>
            <p>
              <strong>CR Number:</strong> {vendor.cr_number}
            </p>
            <p>
              <strong>Taxpayer ID:</strong> {vendor.taxpayer_id}
            </p>
            <p>
              <strong>Bank Name:</strong> {vendor.bank_name}
            </p>
          </div>
        </div>

        {/* Certificates Section */}
        <div className="mt-8">
          <h3 className="text-xl font-semibold mb-4">Certificates</h3>
          {vendor.certificates && Object.keys(vendor.certificates).length > 0 ? (
            <ul className="space-y-4">
              {Object.entries(vendor.certificates).map(([cert, details]) => (
                <li key={cert} className="border p-4 rounded-lg bg-white">
                  <p>
                    <strong>{cert.replace("_", " ")}:</strong>
                  </p>
                  <p>{details.remarks || "No remarks provided."}</p>
                  <a
                    href={`${config.BASE_URL}/vendors/download/${details.file}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 underline"
                  >
                    Download Certificate
                  </a>
                </li>
              ))}
            </ul>
          ) : (
            <p>No certificates uploaded.</p>
          )}
        </div>

        {/* Final Review Section */}
        <div className="mt-12">
          <h3 className="text-2xl font-semibold mb-6">
            Final Review & Evaluation
          </h3>

          {/* Evaluation Table */}
          <table className="w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-200">
                <th className="border px-4 py-2">Category</th>
                <th className="border px-4 py-2">Detail</th>
                <th className="border px-4 py-2">Weightage (%)</th>
                <th className="border px-4 py-2">Max Score</th>
                <th className="border px-4 py-2">Score</th>
              </tr>
            </thead>
            <tbody>
              {evaluation.map((category, index) => (
                <React.Fragment key={index}>
                  <tr className="bg-white font-semibold">
                    <td className="border px-4 py-2" colSpan="3">
                      {category.category}
                    </td>
                    <td className="border px-4 py-2">
                      {category.details.reduce(
                        (acc, detail) => acc + detail.points,
                        0
                      )}
                    </td>
                    <td className="border px-4 py-2"></td>
                  </tr>
                  {category.details.map((detail, subIndex) => (
                    <tr key={subIndex} className="bg-gray-50">
                      <td className="border px-4 py-2"></td>
                      <td className="border px-4 py-2">{detail.description}</td>
                      <td className="border px-4 py-2">{category.weightage}</td>
                      <td className="border px-4 py-2">{detail.points}</td>
                      <td
                        className={`border px-4 py-2 ${getScoreColor(
                          detail.score
                        )}`}
                      >
                        {detail.score}
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>

          {/* Display Final Scores */}
          <div className="mt-6">
            <p className="text-lg">
              Previous Final Score:{" "}
              <span className={getScoreColor(finalScore.previousFinalScore)}>
                {finalScore.previousFinalScore || "N/A"}
              </span>
            </p>
            <p className="text-lg">
              Recent Final Score:{" "}
              <span className={getScoreColor(finalScore.recentFinalScore)}>
                {finalScore.recentFinalScore || "N/A"}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorDetailView;
