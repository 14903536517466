import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const DashboardHeader = () => {
  const [role, setRole] = useState("");
  const [showModal, setShowModal] = useState(false);
  // const [showNotifications, setShowNotifications] = useState(false);
  const [showApps, setShowApps] = useState(false);
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");  // New state for user name

  // Function to close the dropdown when an item is clicked
const handleAppClick = () => {
  setShowApps(false); // Close the dropdown when any app is clicked
};


  // Fetch user details from localStorage when the component mounts
  useEffect(() => {
    const storedRole = localStorage.getItem("userRole");
    const storedUserName = localStorage.getItem("userName");  // Fetch the username from localStorage
    if (storedRole) setRole(storedRole);
    if (storedUserName) setUserName(storedUserName);  // Set the username state
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("userRole");
    localStorage.removeItem("userName");  // Also clear the username when logging out
    navigate("/login");
  };
  

  const getDashboardTitle = () => {
    if (role === "superadmin") {
      return "Super Admin Dashboard";
    } else if (role === "admin") {
      return "Admin Dashboard";
    } else {
      return "User Dashboard";
    }
  };

  // const notifications = [
//   {
//     id: 1,
//     avatar: "https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png",
//     message: "New message from Bonnie Green: 'Hey, what's up?'",
//   },
//   {
//     id: 2,
//     avatar: "https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/jese-leos.png",
//     message: "Jese Leos started following you.",
//   },
// ];


  return (
    <>
      <header className="antialiased">
      <nav className="bg-white border-gray-200 px-6 lg:px-8 py-2.5 dark:bg-gray-800 shadow-md fixed top-0 left-[250px] w-[calc(100%-250px)] z-40">
      <div className="flex justify-between items-center">
            <div className="flex items-center">
            <h1 className="text-2xl font-semibold whitespace-nowrap dark:text-white">
  Welcome, {userName || role}! - {getDashboardTitle()}
</h1>

            </div>
            <div className="flex items-center space-x-4 lg:order-2 relative">
              {/* Notification Button */}
             {/*
<button
  type="button"
  onClick={() => {
    setShowNotifications(!showNotifications);
    setShowApps(false); // Close apps dropdown if open
  }}
  className="p-2 text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
>
  <svg
    className="w-5 h-5"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 14 20"
  >
    <path d="M12.133 10.632v-1.8A5.406 5.406 0 0 0 7.979 3.57.946.946 0 0 0 8 3.464V1.1a1 1 0 0 0-2 0v2.364a.946.946 0 0 0 .021.106 5.406 5.406 0 0 0-4.154 5.262v1.8C1.867 13.018 0 13.614 0 14.807 0 15.4 0 16 .538 16h12.924C14 16 14 15.4 14 14.807c0-1.193-1.867-1.789-1.867-4.175ZM3.823 17a3.453 3.453 0 0 0 6.354 0H3.823Z" />
  </svg>
</button>
*/}

              {/* Notifications Dropdown */}
{/* 
{showNotifications && (
  <div className="absolute top-14 right-0 w-72 bg-white rounded-lg shadow-lg dark:bg-gray-700">
    <div className="block py-2 px-4 text-base font-medium text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
      Notifications
    </div>
    <div className="p-4 space-y-3">
      {notifications.map((notification) => (
        <div key={notification.id} className="flex items-center space-x-3">
          <img
            src={notification.avatar}
            alt="User avatar"
            className="w-8 h-8 rounded-full"
          />
          <span className="text-sm text-gray-500 dark:text-gray-400">
            {notification.message}
          </span>
        </div>
      ))}
    </div>
  </div>
)} 
*/}


              {/* Apps Button */}
              <button
                type="button"
                onClick={() => {
                  setShowApps(!showApps);
                 // setShowNotifications(false); // Close notifications dropdown if open
                }}
                className="p-2 text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
              >
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 18 18"
                >
                  <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
                </svg>
              </button>
              {/* Apps Dropdown */}
{showApps && (
  <div className="absolute top-14 right-0 w-72 bg-white rounded-lg shadow-lg dark:bg-gray-700">
    <div className="block py-2 px-4 text-base font-medium text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
      Apps
    </div>
    <div className="grid grid-cols-2 gap-4 p-4">
      {/* 
<a
  href="#"
  className="block p-4 text-center rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 group"
>
  <svg
    className="mx-auto mb-2 w-5 h-5 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 18 20"
  >
    <path d="M17 5.923A1 1 0 0 0 16 5h-3V4a4 4 0 1 0-8 0v1H2a1 1 0 0 0-1 .923L.086 17.846A2 2 0 0 0 2.08 20h13.84a2 2 0 0 0 1.994-2.153L17 5.923ZM7 9a1 1 0 0 1-2 0V7h2v2Zm0-5a2 2 0 1 1 4 0v1H7V4Zm6 5a1 1 0 1 1-2 0V7h2v2Z" />
  </svg>
  <div className="text-sm font-medium text-gray-900 dark:text-white">
    Vendor Users
  </div>
</a>
<a
  href="#"
  className="block p-4 text-center rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 group"
>
  <svg
    className="mx-auto mb-2 w-5 h-5 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 19"
  >
    <path d="M14.5 0A3.987 3.987 0 0 0 11 2.1a4.977 4.977 0 0 1 3.9 5.858A3.989 3.989 0 0 0 14.5 0ZM9 13h2a4 4 0 0 1 4 4v2H5v-2a4 4 0 0 1 4-4Z" />
    <path d="M5 19h10v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2ZM5 7a5.008 5.008 0 0 1 4-4.9 3.988 3.988 0 1 0-3.9 5.859A4.974 4.974 0 0 1 5 7Zm5 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm5-1h-.424a5.016 5.016 0 0 1-1.942 2.232A6.007 6.007 0 0 1 17 17h2a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5ZM5.424 9H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h2a6.007 6.007 0 0 1 4.366-5.768A5.016 5.016 0 0 1 5.424 9Z" />
  </svg>
  <div className="text-sm font-medium text-gray-900 dark:text-white">
    Profile
  </div>
</a>
<a
  href="#"
  className="block p-4 text-center rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 group"
>
  <svg
    className="mx-auto mb-2 w-5 h-5 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
  >
    <path d="M18 7.5h-.423l-.452-1.09.3-.3a1.5 1.5 0 0 0 0-2.121L16.01 2.575a1.5 1.5 0 0 0-2.121 0l-.3.3-1.089-.452V2A1.5 1.5 0 0 0 11 .5H9A1.5 1.5 0 0 0 7.5 2v.423l-1.09.452-.3-.3a1.5 1.5 0 0 0-2.121 0L2.576 3.99a1.5 1.5 0 0 0 0 2.121l.3.3L2.423 7.5H2A1.5 1.5 0 0 0 .5 9v2A1.5 1.5 0 0 0 2 12.5h.423l.452 1.09-.3.3a1.5 1.5 0 0 0 0 2.121l1.415 1.413a1.5 1.5 0 0 0 2.121 0l.3-.3 1.09.452V18A1.5 1.5 0 0 0 9 19.5h2a1.5 1.5 0 0 0 1.5-1.5v-.423l1.09-.452.3.3a1.5 1.5 0 0 0 2.121 0l1.415-1.414a1.5 1.5 0 0 0 0-2.121l-.3-.3.452-1.09H18a1.5 1.5 0 0 0 1.5-1.5V9A1.5 1.5 0 0 0 18 7.5Zm-8 6a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7Z" />
  </svg>
  <div className="text-sm font-medium text-gray-900 dark:text-white">
    Settings
  </div>
</a>
*/}

  
      <a
  onClick={() => {
    handleAppClick();  // Close the dropdown when KPI is clicked
    navigate('/performance-evaluation');  // Navigate to the KPI page
  }}
  className="block p-4 text-center rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 group cursor-pointer"
>
  <svg
    className="mx-auto mb-2 w-5 h-5 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 18 18"
  >
    <path d="M15.977.783A1 1 0 0 0 15 0H3a1 1 0 0 0-.977.783L.2 9h4.239a2.99 2.99 0 0 1 2.742 1.8 1.977 1.977 0 0 0 3.638 0A2.99 2.99 0 0 1 13.561 9H17.8L15.977.783ZM6 2h6a1 1 0 1 1 0 2H6a1 1 0 0 1 0-2Zm7 5H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Z" />
    <path d="M1 18h16a1 1 0 0 0 1-1v-6h-4.439a.99.99 0 0 0-.908.6 3.978 3.978 0 0 1-7.306 0 .99.99 0 0 0-.908-.6H0v6a1 1 0 0 0 1 1Z" />
  </svg>
  <div className="text-sm font-medium text-gray-900 dark:text-white">KPI</div>
</a>


{/* 
<a
  onClick={() => navigate('/data-table')} // Navigate to the DataTable page
  className="block p-4 text-center rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 group cursor-pointer"
>
  <svg
    className="mx-auto mb-2 w-5 h-5 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 18 18"
  >
    <path d="M15.977.783A1 1 0 0 0 15 0H3a1 1 0 0 0-.977.783L.2 9h4.239a2.99 2.99 0 0 1 2.742 1.8 1.977 1.977 0 0 0 3.638 0A2.99 2.99 0 0 1 13.561 9H17.8L15.977.783ZM6 2h6a1 1 0 1 1 0 2H6a1 1 0 0 1 0-2Zm7 5H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Z" />
    <path d="M1 18h16a1 1 0 0 0 1-1v-6h-4.439a.99.99 0 0 0-.908.6 3.978 3.978 0 0 1-7.306 0 .99.99 0 0 0-.908-.6H0v6a1 1 0 0 0 1 1Z" />
  </svg>
  <div className="text-sm font-medium text-gray-900 dark:text-white">Update Performance</div>
</a>
*/}


    </div>
  </div>
)}


              {/* Logout Button */}
              <button
                type="button"
                onClick={() => setShowModal(true)}
                className="py-2 px-4 ml-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-[#741017] focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              >
                Logout
              </button>
            </div>
          </div>
        </nav>
      </header>

      {/* Confirmation Modal */}
      {showModal && (
        <div
          id="popup-modal"
          className="fixed inset-0 flex items-center justify-center z-50 overflow-y-auto bg-black bg-opacity-50"
        >
          <div className="relative w-full max-w-md p-4 mx-auto">
            <div className="relative bg-white rounded-lg shadow-lg dark:bg-gray-700">
              <button
                type="button"
                onClick={() => setShowModal(false)}
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7L1 13"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <div className="p-4 md:p-5 text-center">
                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                  Are you sure you want to logout?
                </h3>
                <button
                  onClick={handleLogout}
                  className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5"
                >
                  Yes, I'm sure
                </button>
                <button
                  onClick={() => setShowModal(false)}
                  className="py-2.5 px-5 ml-3 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-[#741017] focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                >
                  No, cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardHeader;
