import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.png';
import axios from 'axios';
import io from 'socket.io-client';
import config from '../../config';

const socket = io(`${config.BASE_URL}`);

const Sidebar = () => {
  const [userDetails, setUserDetails] = useState({ email: '', role: '' });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [pendingCount, setPendingCount] = useState(0);

  // Fetch pending vendors from the backend
  const fetchPendingVendors = async () => {
    try {
      const response = await axios.get(`${config.BASE_URL}/vendors?status=Pending`);
      setPendingCount(response.data.length);  // Update the pending count
    } catch (error) {
      console.error('Failed to fetch pending vendors:', error);
    }
  };

  // Fetch user details and pending vendor count when component mounts
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) return;

        const response = await axios.get(`${config.BASE_URL}/auth/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserDetails(response.data);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
    fetchPendingVendors();  // Initial fetch for pending count

    // Listen for vendor updates via WebSocket
    socket.on("vendorUpdated", (data) => {
      if (data.status === "Pending") {
        setPendingCount((prev) => prev + 1);  // Increment if vendor is added to pending
      } else {
        setPendingCount((prev) => Math.max(0, prev - 1));  // Decrement if vendor is reviewed/rejected
      }
    });

    // Cleanup listener when component unmounts
    return () => {
      socket.off("vendorUpdated");
    };
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  

  return (
<div className="sidebar fixed top-0 left-0 w-64 h-screen bg-white shadow-lg overflow-y-auto z-40">
{/* Logo Section */}
<div className="p-6 flex justify-center items-center border-b">
  <img src={logo} alt="Company Logo" className="h-24 w-auto" />
</div>


      {/* User Profile Section */}
      <div className="p-6 text-center border-b">
        <img
src={`https://ui-avatars.com/api/?name=${userDetails.name || 'User'}`}
alt="User Avatar"
          className="w-20 h-20 rounded-full mx-auto"
        />
        <h2 className="mt-4 text-lg font-semibold text-teal-500">{userDetails.name}</h2>
        <p className="text-sm text-gray-500">{userDetails.role}</p>
      </div>

      {/* Navigation Links */}
      <nav className="mt-6 px-4">
      <Link
  to="/user-dashboard"
  className="flex items-center p-4 rounded-lg hover:bg-teal-500 hover:text-white"
>
  <svg className="w-6 h-6 mr-3 fill-[#741017]" viewBox="0 0 576 512">
    <path d="M304 240V16.6c0-9 7-16.6 16-16.6C443.7 0 544 100.3 544 224c0 9-7.6 16-16.6 16H304zM32 272C32 150.7 122.1 50.3 239 34.3c9.2-1.3 17 6.1 17 15.4V288L412.5 444.5c6.7 6.7 6.2 17.7-1.5 23.1C371.8 495.6 323.8 512 272 512C139.5 512 32 404.6 32 272zm526.4 16c9.3 0 16.6 7.8 15.4 17c-7.7 55.9-34.6 105.6-73.9 142.3c-6 5.6-15.4 5.2-21.2-.7L320 288H558.4z"></path>
  </svg>
  User Dashboard
</Link>



                <Link
          to="/dashboard"
          className="flex items-center p-4 rounded-lg hover:bg-teal-500 hover:text-white"
        >
          <svg className="w-6 h-6 mr-3 fill-[#741017]" viewBox="0 0 512 512">
            <path d="M64 256V160H224v96H64zm0 64H224v96H64V320zm224 96V320H448v96H288zM448 256H288V160H448v96zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z"></path>
          </svg>
          Vendor Table
        </Link>


        <Link
  to="/manage-vendors"
  className="flex items-center p-4 rounded-lg hover:bg-teal-500 hover:text-white"
>
  <svg className="w-6 h-6 mr-3 fill-[#741017]" viewBox="0 0 512 512">
    <path d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"></path>
  </svg>
  Manage Vendors
</Link>


<Link
  to="/reports"
  className="flex items-center p-4 rounded-lg hover:bg-teal-500 hover:text-white"
>
  <svg className="w-6 h-6 mr-3 fill-[#741017]" viewBox="0 0 512 512">
    <path d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V400c0 44.2 35.8 80 80 80H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H80c-8.8 0-16-7.2-16-16V64zm406.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L320 210.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L240 221.3l57.4 57.4c12.5 12.5 32.8 12.5 45.3 0l128-128z"></path>
  </svg>
  Reports
</Link>


<Link
  to="/register-action"
  className="flex items-center p-4 rounded-lg hover:bg-teal-500 hover:text-white relative"
>
  <svg className="w-6 h-6 mr-3 fill-[#741017]" viewBox="0 0 576 512">
    <path d="M0 96l576 0c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96zm0 32V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V128H0zM64 405.3c0-29.5 23.9-53.3 53.3-53.3H234.7c29.5 0 53.3 23.9 53.3 53.3c0 5.9-4.8 10.7-10.7 10.7H74.7c-5.9 0-10.7-4.8-10.7-10.7zM176 192a64 64 0 1 1 0 128 64 64 0 1 1 0-128zm176 16c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16z"></path>
  </svg>
  Register Action
  {pendingCount > 0 && (
    <span className="absolute top-3 right-2 bg-red-500 text-white text-xs font-semibold rounded-full px-2 py-0.5">
      {pendingCount}
    </span>
  )}
</Link>






        {userDetails.role === "superadmin" && (
          <>
            <div
              onClick={toggleDropdown}
              className="flex items-center p-4 cursor-pointer rounded-lg hover:bg-teal-500 hover:text-white"
            >
              <svg className={`w-6 h-6 mr-3 ${isDropdownOpen ? 'rotate-180' : ''}`} viewBox="0 0 20 20">
                <path d="M10 12.5L5 8h10l-5 4.5z"></path>
              </svg>
              Users
            </div>

            {isDropdownOpen && (
              <div className="pl-8 space-y-2">
                <Link to="/user-management" className="block p-2 hover:bg-gray-200 rounded-lg">
                  User Management
                </Link>
                <Link to="/create-user" className="block p-2 hover:bg-gray-200 rounded-lg">
                  Create User
                </Link>
              </div>
            )}
          </>
        )}
      </nav>
    </div>
  );
};

export default Sidebar;
