import React from 'react';

const VendorDashboard = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center">
      <h1 className="text-4xl font-bold mb-6">Welcome to Vendor Dashboard</h1>
      <p className="text-lg">Manage your profile and upload files.</p>
    </div>
  );
};

export default VendorDashboard;
