import React, { useEffect, useState } from "react";
import axios from "axios";
import { startOfMonth, endOfMonth } from "date-fns";
import config from "../../config";
import Papa from "papaparse";  // For CSV export

const UsersDashboard = () => {
  const [vendors, setVendors] = useState([]);
  const [supplierCounts, setSupplierCounts] = useState({});
  const [classificationCounts, setClassificationCounts] = useState({});
  const [suppliersThisMonth, setSuppliersThisMonth] = useState([]);
  const [classificationsThisMonth, setClassificationsThisMonth] = useState([]);
  const [startDate, setStartDate] = useState(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState(endOfMonth(new Date()));

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const response = await axios.get(`${config.BASE_URL}/vendors`);
        const vendorsData = response.data;
        setVendors(vendorsData);

        const supplierCounts = {
          "Sub Contractor": 0,
          "Local Supplier": 0,
          "Foreign Supplier": 0,
        };
        const classificationCounts = {};
        const suppliersThisMonth = new Set();
        const classificationsThisMonth = new Set();

        // Calculate counts for suppliers and classifications
        vendorsData.forEach((vendor) => {
          if (vendor.supplier_type) {
            supplierCounts[vendor.supplier_type] =
              (supplierCounts[vendor.supplier_type] || 0) + 1;

            if (
              new Date(vendor.createdAt) >= startOfMonth(new Date()) &&
              new Date(vendor.createdAt) <= endOfMonth(new Date())
            ) {
              suppliersThisMonth.add(vendor.supplier_type);
            }
          }

          if (vendor.classification_type) {
            classificationCounts[vendor.classification_type] =
              (classificationCounts[vendor.classification_type] || 0) + 1;

            if (
              new Date(vendor.createdAt) >= startOfMonth(new Date()) &&
              new Date(vendor.createdAt) <= endOfMonth(new Date())
            ) {
              classificationsThisMonth.add(vendor.classification_type);
            }
          }
        });

        setSupplierCounts(supplierCounts);
        setClassificationCounts(classificationCounts);
        setSuppliersThisMonth(Array.from(suppliersThisMonth));
        setClassificationsThisMonth(Array.from(classificationsThisMonth));
      } catch (error) {
        console.error("Error fetching vendors:", error);
      }
    };

    fetchVendors();
  }, [startDate, endDate]);

  // CSV Download Function
  const handleDownload = (type, isSupplier = true) => {
    const filteredData = vendors.filter((vendor) =>
      isSupplier
        ? vendor.supplier_type === type
        : vendor.classification_type === type
    );
  
    if (filteredData.length === 0) {
      console.warn("No data available for export.");
      return;
    }
  
    const csvData = filteredData.map((vendor) => ({
      "Vendor ID": vendor.vendorID || "N/A",
      Name: vendor.name || "N/A",
      "Phone Extension": vendor.phone_extension || "N/A",
      Phone: vendor.phone || "N/A",
      Email: vendor.email || "N/A",
      "Vendor Type": vendor.vendor_type || "N/A",
      "Local Vendor Name": vendor.local_vendor_name || "N/A",
      "Customer Account": vendor.customer_account || "N/A",
      "Short Address": vendor.short_address || "N/A",
      "Building Number": vendor.building_number || "N/A",
      "Street Secondary Number": vendor.street_secondary_number || "N/A",
      District: vendor.district || "N/A",
      "Address Line 1": vendor.address_line_1 || "N/A",
      "Address Line 2": vendor.address_line_2 || "N/A",
      City: vendor.city || "N/A",
      State: vendor.state || "N/A",
      Postcode: vendor.postcode || "N/A",
      Country: vendor.country || "N/A",
      Website: vendor.website || "N/A",
      "Fax Extension": vendor.fax_extension || "N/A",
      Fax: vendor.fax || "N/A",
      "Contactable Person Name": vendor.contactable_person_name || "N/A",
      "Registerer Email": vendor.register_email || "N/A",
      "CR Number": vendor.cr_number || "N/A",
      "Supplier Type": vendor.supplier_type || "N/A",
      "Classification Type": vendor.classification_type || "N/A",
      "Taxpayer ID": vendor.taxpayer_id || "N/A",
      "Tax Country": vendor.tax_country || "N/A",
      "VAT Number": vendor.vat_number || "N/A",
      "Bank Name": vendor.bank_name || "N/A",
      "Account Name": vendor.account_name || "N/A",
      "Account Number": vendor.account_number || "N/A",
      Branch: vendor.branch || "N/A",
      IBAN: vendor.iban || "N/A",
      Currency: vendor.currency || "N/A",
      "Created At": vendor.createdAt
        ? new Date(vendor.createdAt).toLocaleDateString()
        : "N/A",
    }));
  
    // CSV Generation and Download
    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${isSupplier ? "supplier" : "classification"}_${type}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  

  // Render Tables for Suppliers and Classifications
  const renderTable = (title, data, thisMonth, isSupplier = true) => (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <h3 className="text-lg font-semibold text-gray-800 mb-4">{title}</h3>
      <table className="w-full text-sm text-left text-gray-600">
        <thead>
          <tr>
            <th className="py-3 px-4 bg-gray-100 text-gray-700 font-medium rounded-tl-lg">Type</th>
            <th className="py-3 px-4 bg-gray-100 text-gray-700 font-medium">Counts</th>
            <th className="py-3 px-4 bg-gray-100 text-gray-700 font-medium rounded-tr-lg">Download</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(data).map((item, index) => (
            <tr key={index} className="hover:bg-gray-50 transition">
              <td className="py-3 px-4 border-t">{item}</td>
              <td className="py-3 px-4 border-t">
                {data[item] || 0}
                {thisMonth.includes(item) && (
                  <span className="ml-2 px-2 py-1 text-xs bg-green-100 text-green-800 rounded">
                    + This Month
                  </span>
                )}
              </td>
              <td className="py-3 px-4 border-t">
                <button
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-3 rounded"
                  onClick={() => handleDownload(item, isSupplier)}
                >
                  Download
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-50 text-gray-800 font-sans">
      <main className="w-full min-h-screen">
        {/* Content */}
        <div className="p-6 space-y-6">
          {/* Dashboard Cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {["Sub Contractor", "Local Supplier", "Foreign Supplier"].map(
              (type, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow"
                >
                  <div className="flex justify-between items-center">
                    <div>
                      <div className="text-3xl font-bold text-gray-800">
                        {supplierCounts[type] || 0}
                      </div>
                      <div className="text-sm text-gray-500">{type}</div>
                    </div>
                    <div className="p-2 bg-blue-100 text-blue-500 rounded-full">
                      <i className="ri-user-line text-lg"></i>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>

          {/* Tables Section */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            {renderTable("Supplier Types", supplierCounts, suppliersThisMonth)}
            {renderTable(
              "Classification Types",
              classificationCounts,
              classificationsThisMonth,
              false
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default UsersDashboard;
