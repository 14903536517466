import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import io from "socket.io-client";  // Import WebSocket client
import config from "../../config";
import DashboardHeader from "./Header";  
import PerformanceReview from "./PerformanceReview";



const socket = io(`${config.BASE_URL}`);  // Connect to WebSocket server

const FinalReview = () => {
  const { vendorID } = useParams();
  const [vendor, setVendor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [finalScore, setFinalScore] = useState(null);
  const [submitted, setSubmitted] = useState(false);  // New state for submission success
  const [validationError, setValidationError] = useState(false);

  const navigate = useNavigate();

  const [evaluation, setEvaluation] = useState([
    {
      category: "Quality Compliance",
      weightage: 30,
      details: [
        {
          description: "GOV APPROVED VEDNOR Registration ",
          points: 5,
          score: "",
        },
        {
          description: "ISO-9001-2015, ISO 45001-2018, ISO 14001-2018 Certifications",
          points: 5,
          score: "",
        },
        {
          description: "ASTM, BS Certifications",
          points: 5,
          score: "",
        },
        {
          description: "Review their QA/QC manuals",
          points: 5,
          score: "",
        },
        {
          description: "Company Audit Report ",
          points: 5,
          score: "",
        },
        {
          description: "Testing Facilities (In-house/Third-party)",
          points: 5,
          score: "",
        }
      ]
    },
    {
      category: "Regulatory and Legal Compliance",
      weightage: 30,
      details: [
        {
          description: "Registration membership in the Chamber of Commerce - Valid ",
          points: 5,
          score: "",
        },
        {
          description: "Social insurance certificate. (GOSI).  - Valid ",
          points: 5,
          score: "",
        },
        {
          description: "VAT registration certificate.  - Valid ",
          points: 5,
          score: "",
        },
        {
          description: "The Saudization certificate - Valid ",
          points: 5,
          score: "",
        },
        {
          description: "Zakat Certificate - Valid ",
          points: 5,
          score: "",
        },
        {
          description: "SASO Certificate - Valid ",
          points: 5,
          score: "",
        }
      ]
    },
    {
      category: "Overview",
      weightage: 15,
      details: [
        {
          description: "Company Portfolio",
          points: 5,
          score: "",
        },
        {
          description: "Company organization & Technical Capability ",
          points: 5,
          score: "",
        },
        {
          description: "Financial Capability",
          points: 5,
          score: "",
        }
      ]
    },
    {
      category: "Project Completion and Commitment",
      weightage: 15,
      details: [
        {
          description: "Previous Project Details",
          points: 5,
          score: "",
        },
        {
          description: "Completion Project Approval Documents",
          points: 5,
          score: "",
        },
        {
          description: "Project Scope Compliance and Commitment",
          points: 5,
          score: "",
        }
      ]
    },
    {
      category: "Delivery Timeline",
      weightage: 10,
      details: [
        {
          description: "Delivery/Proposal Timeline",
          points: 5,
          score: "",
        },
        {
          description: "Outsource/Insource (OKK/IK) Control",
          points: 5,
          score: "",
        }
      ]
    }
  ]);
  
  

  useEffect(() => {
  const fetchVendor = async () => {
    if (!vendorID) {
      setError("Vendor ID is missing.");
      setLoading(false);
      return;
    }
    try {
      const response = await axios.get(`${config.BASE_URL}/vendors/${vendorID}`);
      if (response.data) {
        setVendor(response.data);
      } else {
        setError("Vendor not found.");
      }
    } catch (err) {
      setError("Failed to fetch vendor details.");
    } finally {
      setLoading(false);
    }
  };

  fetchVendor();

  // Listen for WebSocket vendor updates (global update for all users)
  socket.on("vendorUpdated", (data) => {
    if (data.vendorID === vendorID) {
      setVendor((prev) => (prev ? { ...prev, status: data.status } : null));
    }
  });

  // Listen for review submission success (targeted update for submitting user)
  socket.on("reviewSubmitted", (data) => {
    if (data.vendorID === vendorID) {
      setSubmitted(true);
    }
  });

  // Cleanup WebSocket listeners to avoid memory leaks
  return () => {
    socket.off("vendorUpdated");
    socket.off("reviewSubmitted");
  };
}, [vendorID]);


const calculateFinalScore = () => {
  if (!Array.isArray(evaluation)) {
    console.error("Evaluation is not an array!", evaluation);
    return;
  }

  let totalWeightedScore = 0;

  evaluation.forEach((category) => {
    const categoryMaxPoints = category.details.reduce(
      (sum, detail) => sum + detail.points,
      0
    );

    const categoryActualScore = category.details.reduce(
      (sum, detail) => sum + (parseInt(detail.score, 10) || 0),
      0
    );

    if (categoryMaxPoints > 0) {
      const categoryWeightedScore =
        (categoryActualScore / categoryMaxPoints) * category.weightage;
      totalWeightedScore += categoryWeightedScore;
    }
  });

  const finalScore = ((totalWeightedScore / 100) * 10).toFixed(2);
  console.log("Final Score Calculated:", finalScore); // Debugging log
  setFinalScore(finalScore);
};





  const handleScoreChange = (categoryIndex, detailIndex, value) => {
    const updatedEvaluation = [...evaluation];
    
    // Ensure value is treated as a string
    const stringValue = value.toString();
  
    // Remove leading zeros but allow "0" as a valid input
    const cleanValue = stringValue === "0" ? "0" : stringValue.replace(/^0+(?=[1-9])/, "");
  
    const validValue =
      cleanValue === "" || (parseInt(cleanValue) >= 0 && parseInt(cleanValue) <= 10)
        ? cleanValue
        : updatedEvaluation[categoryIndex].details[detailIndex].score;
  
    // Update the specific detail's score
    updatedEvaluation[categoryIndex].details[detailIndex].score = validValue;
    setEvaluation(updatedEvaluation);
  };
  

  const handleSubmit = async () => {
    const isIncomplete = evaluation.some((e) => 
      e.details.some((d) => d.score === "")
    );
  
    if (isIncomplete) {
      setValidationError(true);
      return;
    }
  
    setValidationError(false);
  
    try {
      const payload = {
        vendorID,
        evaluation,
        finalScore, // Save the final score
        evaluationType: "first", // Mark as first evaluation
      };
  
      const response = await axios.post(`${config.BASE_URL}/review/submit`, payload);
  
      if (response.status === 200) {
        setSubmitted(true);
        setFinalScore(null);
        setEvaluation(evaluation.map((e) => ({
          ...e,
          details: e.details.map((d) => ({ ...d, score: "" }))
        })));
  
        // Update vendor status in the backend
        await axios.put(`${config.BASE_URL}/vendors/${vendorID}`, { status: "Reviewed" });
  
        navigate(`/vendors/${vendorID}`); // Redirect to VendorDetailView after submission
      }
    } catch (error) {
      console.error("Error submitting review:", error);
      alert("Failed to submit review.");
    }
  };
  
  

  // Prevent accessing vendor properties directly if vendor is null
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  // Guard: Prevent rendering if vendor is null
  if (!vendor) {
    return (
      <div className="text-center p-6">
        <h2 className="text-2xl font-bold text-red-500">Vendor not found!</h2>
        <p className="text-gray-600">The requested vendor details are not available.</p>
        <button
          onClick={() => navigate(-1)}
          className="mt-4 px-6 py-2 bg-blue-600 text-white rounded-lg"
        >
          Go Back
        </button>
      </div>
    );
  }


  return (
    <div className="container mx-auto p-6">

       <DashboardHeader />

      <h2 className="text-2xl font-bold mb-4">Final Review</h2>


      {/* Vendor Details */}
      <div className="border p-4 rounded-lg shadow-md bg-gray-50 mb-6">
        <h3 className="text-lg font-semibold mb-4">Vendor Details</h3>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" className="p-4">
                    <div className="flex items-center">
                        <input
                            id="checkbox-all-search"
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            disabled
                            checked
                        />
                        <label
                            htmlFor="checkbox-all-search"
                            className="sr-only"
                        >
                            checkbox
                        </label>
                    </div>
                </th>
                <th scope="col" className="px-6 py-3">Vendor</th>
                <th scope="col" className="px-6 py-3">Details</th>
                <th scope="col" className="px-6 py-3">Status</th>
            </tr>
        </thead>
        <tbody>
            {Object.entries({
                "Vendor ID": vendor.vendorID,
                Name: vendor.name,
                "Phone Extension": vendor.phone_extension,
                Phone: vendor.phone,
                Email: vendor.email,
                "Vendor Type": vendor.vendor_type,
                "Local Vendor Name": vendor.local_vendor_name,
                "Customer Account": vendor.customer_account,
                "Short Address": vendor.short_address,
                "Building Number": vendor.building_no,
                "Street Secondary Number": vendor.street_secondary_number,
                District: vendor.district,
                "Address Line 1": vendor.address_line_1,
                "Address Line 2": vendor.address_line_2,
                City: vendor.city,
                State: vendor.state,
                Postcode: vendor.postcode,
                Country: vendor.country,
                Website: vendor.website,
                "Fax Extension": vendor.fax_extension,
                Fax: vendor.fax,
                "Registerer Name": vendor.contactable_person_name,
                "Registerer Email": vendor.register_email,
                "CR Number": vendor.cr_number,
                "Supplier Type": vendor.supplier_type,
                "Classification Type": vendor.classification_type,
                "Taxpayer ID": vendor.taxpayer_id,
                "Tax Country": vendor.tax_country,
                "VAT Number": vendor.vat_number,
                "Bank Name": vendor.bank_name,
                "Account Name": vendor.account_name,
                "Account Number": vendor.account_number,
                Branch: vendor.branch,
                IBAN: vendor.iban,
                Currency: vendor.currency,
            }).map(([label, value]) => (
                <tr
                    key={label}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                    <td className="w-4 p-4">
                        <div className="flex items-center">
                            <input
                                type="checkbox"
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                checked={!!value}
                                disabled
                            />
                        </div>
                    </td>
                    <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                        {label}
                    </th>
                    <td className="px-6 py-4">{value || <span className="text-red-500">Missing</span>}</td>
                    <td className="px-6 py-4">
                        {value ? (
                            <span className="text-green-600 font-medium">Valid</span>
                        ) : (
                            <span className="text-red-600 font-medium">Invalid</span>
                        )}
                    </td>
                </tr>
            ))}
        </tbody>
    </table>
</div>

      </div>

     {/* Evaluation Table */}
<div className="relative overflow-x-auto mb-6">
  {vendor?.status === "Reviewed" ? (
    <PerformanceReview />
  ) : (
    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
      <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
        <tr>
          <th className="px-6 py-3">S.No</th>
          <th className="px-6 py-3">Quality Indicator & Details</th>
          <th className="px-6 py-3">Weightage (%)</th>
          <th className="px-6 py-3">Max Score</th>
          <th className="px-6 py-3">Score (out of 10)</th>
        </tr>
      </thead>
      <tbody>
        {evaluation.map((e, categoryIndex) => (
          <React.Fragment key={categoryIndex}>
            <tr className="bg-white dark:bg-gray-800">
              <td className="px-6 py-4">{categoryIndex + 1}</td>
              <td className="px-6 py-4 font-medium text-gray-900 dark:text-white">
                {e.category}
              </td>
              <td className="px-6 py-4">{e.weightage}%</td>
              <td className="px-6 py-4">
                {e.details.reduce((acc, detail) => acc + detail.points, 0)}
              </td>
              <td className="px-6 py-4"></td>
            </tr>

            {/* Render the details below the main category */}
            {e.details.map((detail, detailIndex) => (
              <tr key={`${categoryIndex}-${detailIndex}`} className="bg-gray-50 dark:bg-gray-900">
                <td className="px-6 py-4"></td>
                <td className="px-6 py-4">{detail.description}</td>
                <td className="px-6 py-4"></td>
                <td className="px-6 py-4">{detail.points}</td>
                <td className="px-6 py-4">
                <input
  type="number"
  min="0"
  max="5"
  value={detail.score}
  onChange={(ev) => {
    const value = ev.target.value === "" ? "" : parseInt(ev.target.value);
    handleScoreChange(categoryIndex, detailIndex, value > 5 ? 5 : value);
  }}
  onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
  className="w-20 p-1 border rounded"
/>


                </td>
              </tr>
            ))}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  )}
</div>





    {/* Final Score */}
    {finalScore && (
  <div className="flex items-center mb-5">
    <p className="bg-blue-100 text-blue-800 text-sm font-semibold inline-flex items-center p-1.5 rounded dark:bg-blue-200 dark:text-blue-800">
      {finalScore}
    </p>
    <p className="ms-2 font-medium text-gray-900 dark:text-white">
      {finalScore >= 8
        ? "Excellent"
        : finalScore >= 6
        ? "Good"
        : "Needs Improvement"}
    </p>
    <span className="w-1 h-1 mx-2 bg-gray-900 rounded-full dark:bg-gray-500"></span>
    <p className="text-sm font-medium text-gray-500 dark:text-gray-400">
      Based on evaluation
    </p>
  </div>
)}


{/* Detailed Evaluation */}
{vendor?.status !== "Reviewed" && (
  <div className="gap-8 sm:grid sm:grid-cols-2 mb-6">
    {evaluation.map((e, categoryIndex) => (
      <dl key={categoryIndex}>
        <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">
          {e.category}
        </dt>

        {e.details.map((detail, detailIndex) => (
          <dd key={detailIndex} className="flex items-center mb-3">
            <div className="w-full bg-gray-200 rounded h-2.5 dark:bg-gray-700 me-2">
              <div
                className="bg-blue-600 h-2.5 rounded dark:bg-blue-500"
                style={{
                  width: `${detail.score ? (detail.score / detail.points) * 100 : 0}%`,
                }}
              ></div>
            </div>
            <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
              {detail.score || "N/A"}
            </span>
          </dd>
        ))}
      </dl>
    ))}
  </div>
)}

{/* Calculate Score Button */}
{vendor?.status !== "Reviewed" && (
  <div className="relative">
    <button
      onClick={calculateFinalScore}
      className={`px-6 py-2 rounded-lg shadow flex items-center justify-center ${
        evaluation.some((e) =>
          e.details.some((detail) => detail.score === "")
        )
          ? "bg-gray-400 cursor-not-allowed"
          : "bg-green-600 hover:bg-green-800 text-white"
      }`}
      disabled={evaluation.some((e) =>
        e.details.some((detail) => detail.score === "")
      )}
    >
      Calculate Final Score
      {evaluation.some((e) =>
        e.details.some((detail) => detail.score === "")
      ) && (
        <span className="ml-2 text-red-500 font-bold">!</span>
      )}
    </button>

    {evaluation.some((e) =>
      e.details.some((detail) => detail.score === "")
    ) && (
      <p className="text-xs text-red-500 mt-1">
        Please fill all fields
      </p>
    )}
  </div>
)}

{/* Vendor Already Reviewed */}
{vendor?.status === "Reviewed" && (
  <div className="p-4 bg-yellow-100 rounded-lg text-yellow-800 mb-6">
    <p className="text-lg font-semibold">The vendor has already been reviewed.</p>
    <p className="text-sm">No further actions are required at this stage.</p>
  </div>
)}



     {/* Navigation Buttons */}
     {vendor?.status !== "Reviewed" && (
  <div className="flex gap-4 mt-6">
    <button
      onClick={() => navigate(-1)}
      className="px-6 py-2 bg-gray-500 text-white rounded-lg shadow hover:bg-gray-700"
    >
      Back
    </button>
    <button
  type="submit"
  onClick={handleSubmit}
  className={`px-6 py-2 rounded-lg shadow ${
    evaluation.some((e) => e.details.some((d) => d.score === ""))
      ? "bg-gray-400 cursor-not-allowed"
      : "bg-blue-600 hover:bg-blue-800 text-white"
  }`}
  disabled={evaluation.some((e) => e.details.some((d) => d.score === ""))}
>
  Submit Review
</button>

  </div>
)}
    </div>
  );
};

export default FinalReview;
