import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import config from "../../config";

const PerformanceReview = () => {
  const { vendorID } = useParams();
  const [vendor, setVendor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [evaluation, setEvaluation] = useState([
    {
      category: "Quality Compliance",
      weightage: 30,
      details: [
        {
          description: "GOV APPROVED VEDNOR Registration ",
          points: 5,
          score: "",
        },
        {
          description: "ISO-9001-2015, ISO 45001-2018, ISO 14001-2018 Certifications",
          points: 5,
          score: "",
        },
        {
          description: "ASTM, BS Certifications",
          points: 5,
          score: "",
        },
        {
          description: "Review their QA/QC manuals",
          points: 5,
          score: "",
        },
        {
          description: "Company Audit Report ",
          points: 5,
          score: "",
        },
        {
          description: "Testing Facilities (In-house/Third-party)",
          points: 5,
          score: "",
        }
      ]
    },
    {
      category: "Regulatory and Legal Compliance",
      weightage: 30,
      details: [
        {
          description: "Registration membership in the Chamber of Commerce - Valid ",
          points: 5,
          score: "",
        },
        {
          description: "Social insurance certificate. (GOSI).  - Valid ",
          points: 5,
          score: "",
        },
        {
          description: "VAT registration certificate.  - Valid ",
          points: 5,
          score: "",
        },
        {
          description: "The Saudization certificate - Valid ",
          points: 5,
          score: "",
        },
        {
          description: "Zakat Certificate - Valid ",
          points: 5,
          score: "",
        },
        {
          description: "SASO Certificate - Valid ",
          points: 5,
          score: "",
        }
      ]
    },
    {
      category: "Overview",
      weightage: 15,
      details: [
        {
          description: "Company Portfolio",
          points: 5,
          score: "",
        },
        {
          description: "Company organization & Technical Capability ",
          points: 5,
          score: "",
        },
        {
          description: "Financial Capability",
          points: 5,
          score: "",
        }
      ]
    },
    {
      category: "Project Completion and Commitment",
      weightage: 15,
      details: [
        {
          description: "Previous Project Details",
          points: 5,
          score: "",
        },
        {
          description: "Completion Project Approval Documents",
          points: 5,
          score: "",
        },
        {
          description: "Project Scope Compliance and Commitment",
          points: 5,
          score: "",
        }
      ]
    },
    {
      category: "Delivery Timeline",
      weightage: 10,
      details: [
        {
          description: "Delivery/Proposal Timeline",
          points: 5,
          score: "",
        },
        {
          description: "Outsource/Insource (OKK/IK) Control",
          points: 5,
          score: "",
        }
      ]
    }
  ]);
  const [validationError, setValidationError] = useState(false);
  const [finalScore, setFinalScore] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVendor = async () => {
      try {
        const response = await axios.get(`${config.BASE_URL}/vendors/${vendorID}`);
        if (response.data.status === "Reviewed") {
          setVendor(response.data);
        } else {
          navigate(`/vendors/${vendorID}`);
        }
      } catch (err) {
        console.error("Error fetching vendor details:", err);
        navigate("/error");
      } finally {
        setLoading(false);
      }
    };

    fetchVendor();
  }, [vendorID, navigate]);

  const handleScoreChange = (categoryIndex, detailIndex, value) => {
    const updatedEvaluation = [...evaluation];
    const cleanValue = value === "0" ? "0" : value.replace(/^0+(?=[1-9])/, "");

    const validValue =
      cleanValue === "" || (parseInt(cleanValue) >= 0 && parseInt(cleanValue) <= updatedEvaluation[categoryIndex].details[detailIndex].points)
        ? cleanValue
        : updatedEvaluation[categoryIndex].details[detailIndex].score;

    updatedEvaluation[categoryIndex].details[detailIndex].score = validValue;
    setEvaluation(updatedEvaluation);
  };

  const calculateFinalScore = () => {
    let totalWeightedScore = 0;
  
    evaluation.forEach((category) => {
      const categoryTotal = category.details.reduce(
        (sum, detail) => sum + (parseInt(detail.score) || 0),
        0
      );
      const categoryMax = category.details.reduce((sum, detail) => sum + detail.points, 0);
  
      // Ensure no division by zero
      if (categoryMax > 0) {
        totalWeightedScore += (categoryTotal / categoryMax) * category.weightage;
      }
    });
  
    // Normalize to a scale of 10
    const final = (totalWeightedScore / 10).toFixed(2);
    setFinalScore(final);
  };
  

  const handleSubmit = async () => {
    const isIncomplete = evaluation.some((category) =>
      category.details.some((detail) => detail.score === "")
    );

    if (isIncomplete) {
      setValidationError(true);
      return;
    }

    try {
      const payload = { vendorID, evaluation, type: "SecondReview" };
      const response = await axios.post(`${config.BASE_URL}/review/submit`, payload);

      if (response.status === 200) {
        alert("Second Review Submitted Successfully!");
        navigate(`/vendors/${vendorID}`);
      }
    } catch (error) {
      console.error("Error submitting review", error);
      alert("Failed to submit review.");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto p-6">
      <h2 className="text-2xl font-bold mb-4">Second Performance Review</h2>

      <table className="w-full text-sm text-left text-gray-500 border mb-6">
        <thead className="text-xs text-gray-700 uppercase bg-gray-100">
          <tr>
            <th className="px-6 py-3">S.No</th>
            <th className="px-6 py-3">Category</th>
            <th className="px-6 py-3">Weightage (%)</th>
            <th className="px-6 py-3">Max Points</th>
            <th className="px-6 py-3">Score</th>
          </tr>
        </thead>
        <tbody>
          {evaluation.map((category, categoryIndex) => (
            <React.Fragment key={categoryIndex}>
              <tr className="bg-white font-bold">
                <td className="px-6 py-4">{categoryIndex + 1}</td>
                <td className="px-6 py-4">{category.category}</td>
                <td className="px-6 py-4">{category.weightage}</td>
                <td className="px-6 py-4">
                  {category.details.reduce((acc, detail) => acc + detail.points, 0)}
                </td>
                <td className="px-6 py-4"></td>
              </tr>
              {category.details.map((detail, detailIndex) => (
                <tr key={detailIndex} className="bg-gray-50">
                  <td className="px-6 py-4"></td>
                  <td className="px-6 py-4">{detail.description}</td>
                  <td className="px-6 py-4"></td>
                  <td className="px-6 py-4">{detail.points}</td>
                  <td className="px-6 py-4">
                    <input
                      type="number"
                      value={detail.score}
                      onChange={(e) => handleScoreChange(categoryIndex, detailIndex, e.target.value)}
                      className={`w-16 p-1 border rounded ${
                        detail.score === "" ? "border-red-500" : "border-gray-300"
                      }`}
                      min="0"
                      max={detail.points}
                    />
                    {detail.score === "" && (
                      <p className="text-xs text-red-500">Required</p>
                    )}
                  </td>
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      {finalScore && (
        <div className="mb-4">
          <p className="text-lg font-semibold">
            Final Score:{" "}
            <span className={`text-${finalScore >= 8 ? "green" : finalScore >= 6 ? "yellow" : "red"}-500`}>
              {finalScore}
            </span>
          </p>
        </div>
      )}

      <div className="flex gap-4">
        <button
          onClick={calculateFinalScore}
          className="px-4 py-2 bg-green-600 text-white rounded shadow"
        >
          Calculate Final Score
        </button>
        <button
          onClick={handleSubmit}
          className="px-4 py-2 bg-blue-600 text-white rounded shadow"
        >
          Submit Review
        </button>
      </div>
    </div>
  );
};

export default PerformanceReview;
