import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import config from "../../config";
import DashboardHeader from "./Header";
import Sidebar from "../dashboard/Sidebar";  // Import the Sidebar component


const VendorRecords = () => {
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedRemarks, setSelectedRemarks] = useState("");

  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [vendorsPerPage] = useState(10);

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const response = await axios.get(`${config.BASE_URL}/vendors`);
        const vendors = response.data;

        const filteredVendors = vendors
          .filter((vendor) => {
            const matchesStatus =
              statusFilter === "all" || vendor.status.toLowerCase() === statusFilter.toLowerCase();
            const matchesSearch =
              vendor.vendorID.toLowerCase().includes(searchQuery.toLowerCase()) ||
              vendor.name.toLowerCase().includes(searchQuery.toLowerCase());
            return matchesStatus && matchesSearch;
          })
          .map(async (vendor) => {
            const finalScore = await fetchEvaluation(vendor._id);
            return { ...vendor, finalScore };
          });

        const vendorsWithScores = await Promise.all(filteredVendors);
        setVendors(vendorsWithScores);
      } catch (err) {
        setError("Failed to fetch vendor records.");
      } finally {
        setLoading(false);
      }
    };

    fetchVendors();
  }, [searchQuery, statusFilter]);

  const fetchEvaluation = async (vendorID) => {
    try {
      const evalResponse = await axios.get(`${config.BASE_URL}/review/${vendorID}`);
      if (evalResponse.data) {
        const review = evalResponse.data;
        return {
          previousFinalScore: review.previousFinalScore || "N/A",
          recentFinalScore: review.recentFinalScore || "N/A",
        };
      }
    } catch {
      return { previousFinalScore: "N/A", recentFinalScore: "N/A" };
    }
  };

  const indexOfLastVendor = currentPage * vendorsPerPage;
  const indexOfFirstVendor = indexOfLastVendor - vendorsPerPage;
  const currentVendors = vendors.slice(indexOfFirstVendor, indexOfLastVendor);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const openModal = (remarks) => {
    setSelectedRemarks(remarks || "No remarks available.");
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedRemarks("");
  };

  if (loading) return <div>Loading vendors...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="container mx-auto p-6">
      <DashboardHeader />
      <h2 className="text-2xl font-bold mb-6">Vendor Records</h2>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <div className="flex flex-col sm:flex-row items-center justify-between pb-4">
          <div>
            <select
              className="inline-flex items-center text-gray-500 bg-white border border-gray-300 rounded-lg text-sm px-3 py-1.5 hover:bg-gray-100"
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
            >
              <option value="all">All Statuses</option>
              <option value="Reviewed">Reviewed</option>
              <option value="Pending">Pending</option>
            </select>
          </div>
          <div className="relative">
            <input
              type="text"
              placeholder="Search by Vendor ID or Name"
              className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th className="px-6 py-3">Vendor ID</th>
              <th className="px-6 py-3">Name</th>
              <th className="px-6 py-3">Status</th>
              <th className="px-6 py-3">Certificates</th>
              <th className="px-6 py-3">Final Score</th>
              <th className="px-6 py-3">Remarks</th>
              <th className="px-6 py-3">Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentVendors.map((vendor) => (
              <tr
                key={vendor._id}
                className="bg-white border-b hover:bg-gray-50"
              >
                <td className="px-6 py-4">{vendor.vendorID}</td>
                <td className="px-6 py-4">{vendor.name}</td>
                <td className="px-6 py-4">
                  {vendor.status || <span className="text-red-500">Pending</span>}
                </td>
                <td className="px-6 py-4">
                  {vendor.certificates ? (
                    <span className="text-green-500">Uploaded</span>
                  ) : (
                    <span className="text-gray-400">None</span>
                  )}
                </td>
                <td className="px-6 py-4">
                  {vendor.finalScore ? (
                    <>
                      {vendor.finalScore.previousFinalScore} →{" "}
                      <span className="text-green-500 font-semibold">
                        {vendor.finalScore.recentFinalScore}
                      </span>
                    </>
                  ) : (
                    "N/A"
                  )}
                </td>
                <td className="px-6 py-4">
                  <button
                    onClick={() => openModal(vendor.remarks)}
                    className="text-blue-500 hover:underline"
                  >
                    View Remarks
                  </button>
                </td>
                <td className="px-6 py-4 flex gap-2">
                  <Link
                    to={`/vendors/${vendor._id}`}
                    className="text-blue-500 hover:underline"
                  >
                    View
                  </Link>
                  {localStorage.getItem("userRole") === "superadmin" && (
                    <Link
                      to={`/final-review/${vendor._id}`}
                      className="text-green-500 hover:underline"
                    >
                      Edit
                    </Link>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="mt-4 flex justify-center">
  <nav aria-label="Page navigation example">
    <ul className="flex items-center -space-x-px h-8 text-sm">
      <li>
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className={`flex items-center justify-center px-3 h-8 ms-0 leading-tight ${
            currentPage === 1 ? "text-gray-400" : "text-gray-500 hover:text-gray-700 hover:bg-gray-100"
          } bg-white border border-e-0 border-gray-300 rounded-s-lg`}
        >
          <span className="sr-only">Previous</span>
          <svg
            className="w-2.5 h-2.5 rtl:rotate-180"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 1 1 5l4 4"
            />
          </svg>
        </button>
      </li>

      {[...Array(Math.ceil(vendors.length / vendorsPerPage))].map((_, index) => (
        <li key={index}>
          <button
            onClick={() => paginate(index + 1)}
            className={`flex items-center justify-center px-3 h-8 leading-tight ${
              currentPage === index + 1
                ? "text-blue-600 border border-blue-300 bg-blue-50"
                : "text-gray-500 hover:text-gray-700 hover:bg-gray-100"
            } border border-gray-300`}
          >
            {index + 1}
          </button>
        </li>
      ))}

      <li>
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === Math.ceil(vendors.length / vendorsPerPage)}
          className={`flex items-center justify-center px-3 h-8 leading-tight ${
            currentPage === Math.ceil(vendors.length / vendorsPerPage)
              ? "text-gray-400"
              : "text-gray-500 hover:text-gray-700 hover:bg-gray-100"
          } bg-white border border-gray-300 rounded-e-lg`}
        >
          <span className="sr-only">Next</span>
          <svg
            className="w-2.5 h-2.5 rtl:rotate-180"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 9 4-4-4-4"
            />
          </svg>
        </button>
      </li>
    </ul>
  </nav>
</div>

      </div>

      {showModal && (
        <div className="fixed inset-0 z-[999] flex items-center justify-center bg-black bg-opacity-60 backdrop-blur-sm">
          <div className="relative w-1/3 bg-white rounded-lg shadow-md p-6">
            <div className="text-xl font-medium mb-4">Remarks</div>
            <div className="text-gray-700 mb-6 p-4 border border-gray-200 rounded whitespace-normal break-words">
              {selectedRemarks}
            </div>
            <div className="flex justify-end">
              <button
                onClick={closeModal}
                className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VendorRecords;
