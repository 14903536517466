import React from "react";

const DataTable = () => {
  const data = [
    {
      image: "https://via.placeholder.com/40",
      name: "Jenny Wilson",
      phone: "(307) 555-0133",
      company: "Louis Vuitton",
      date: "October 24, 2018",
      status: "High",
    },
    {
      image: "https://via.placeholder.com/40",
      name: "Floyd Miles",
      phone: "(229) 555-0109",
      company: "Ferrari",
      date: "November 7, 2017",
      status: "Low",
    },
    {
      image: "https://via.placeholder.com/40",
      name: "Ralph Edwards",
      phone: "(270) 555-0117",
      company: "Sony",
      date: "August 7, 2017",
      status: "Medium",
    },
    {
      image: "https://via.placeholder.com/40",
      name: "Esther Howard",
      phone: "(302) 555-0107",
      company: "General Electric",
      date: "October 31, 2017",
      status: "High",
    },
    {
      image: "https://via.placeholder.com/40",
      name: "Annette Black",
      phone: "(252) 555-0126",
      company: "eBay",
      date: "May 29, 2017",
      status: "Medium",
    },
    {
      image: "https://via.placeholder.com/40",
      name: "Cody Fisher",
      phone: "(603) 555-0123",
      company: "MasterCard",
      date: "February 28, 2018",
      status: "High",
    },
    {
      image: "https://via.placeholder.com/40",
      name: "Dianne Russell",
      phone: "(303) 555-0150",
      company: "Starbucks",
      date: "December 2, 2018",
      status: "Low",
    },
    {
      image: "https://via.placeholder.com/40",
      name: "Arlene McCoy",
      phone: "(907) 555-0101",
      company: "Bank of America",
      date: "February 9, 2015",
      status: "High",
    },
    {
      image: "https://via.placeholder.com/40",
      name: "Courtney Henry",
      phone: "(205) 555-0100",
      company: "Louis Vuitton",
      date: "May 9, 2014",
      status: "Medium",
    },
  ];

  const getStatusClass = (status) => {
    switch (status) {
      case "High":
        return "bg-green-100 text-green-700";
      case "Medium":
        return "bg-yellow-100 text-yellow-700";
      case "Low":
        return "bg-red-100 text-red-700";
      default:
        return "bg-gray-100 text-gray-700";
    }
  };

  return (
    <div className="container mx-auto p-6">
      <table className="min-w-full table-auto bg-white shadow-lg rounded-lg">
        <thead>
          <tr className="bg-gray-100 text-gray-600 uppercase text-sm leading-normal">
            <th className="py-3 px-6 text-left">Image</th>
            <th className="py-3 px-6 text-left">Name</th>
            <th className="py-3 px-6 text-left">Phone</th>
            <th className="py-3 px-6 text-left">Company</th>
            <th className="py-3 px-6 text-left">Date</th>
            <th className="py-3 px-6 text-left">Status</th>
            <th className="py-3 px-6 text-center">Action</th>
          </tr>
        </thead>
        <tbody className="text-gray-600 text-sm font-light">
          {data.map((item, index) => (
            <tr
              key={index}
              className="border-b border-gray-200 hover:bg-gray-50"
            >
              <td className="py-3 px-6 text-left">
                <img
                  src={item.image}
                  alt="avatar"
                  className="w-10 h-10 rounded-full"
                />
              </td>
              <td className="py-3 px-6 text-left">{item.name}</td>
              <td className="py-3 px-6 text-left">{item.phone}</td>
              <td className="py-3 px-6 text-left">{item.company}</td>
              <td className="py-3 px-6 text-left">{item.date}</td>
              <td className="py-3 px-6 text-left">
                <span
                  className={`py-1 px-3 rounded-full text-xs ${getStatusClass(
                    item.status
                  )}`}
                >
                  {item.status}
                </span>
              </td>
              <td className="py-3 px-6 text-center">
                <div className="flex item-center justify-center">
                  <button className="w-4 mr-2 transform hover:text-indigo-500 hover:scale-110">
                    ✏️
                  </button>
                  <button className="w-4 mr-2 transform hover:text-red-500 hover:scale-110">
                    🗑️
                  </button>
                  <button className="w-4 transform hover:text-blue-500 hover:scale-110">
                    ⋮
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;