import React, { useState, useEffect } from "react";
import axios from "axios";
import io from "socket.io-client";  // Import WebSocket client
import { useNavigate } from "react-router-dom"; // Import for navigation
import config from "../../config";

// Initialize WebSocket connection
const socket = io(`${config.BASE_URL}`);

const RegisterVendor = () => {
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const stages = ["Application", "Application Review", "Final Review"];
  const [currentStage, setCurrentStage] = useState(0);

  const navigate = useNavigate();

  // Fetch vendor data from backend (Only Pending Vendors)
  const fetchVendors = async () => {
    try {
      const response = await axios.get(`${config.BASE_URL}/vendors?status=Pending`);
      setVendors(response.data);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching vendor data:", err.response || err.message);
      setError("Failed to fetch vendors.");
      setLoading(false);
    }
  };

  // Handle Refresh button click
  const handleRefresh = () => {
    fetchVendors();  // Call the fetch function
  };

  // Move vendor to the next stage
  const moveToNextStage = async (vendorId, currentStage) => {
    const nextStageIndex = stages.indexOf(currentStage) + 1;
    if (nextStageIndex >= stages.length) {
      return navigate("/manage-vendors");
    }

    try {
      await axios.put(`${config.BASE_URL}/vendors/${vendorId}`, {
        stage: stages[nextStageIndex],
      });

      navigate(`/${stages[nextStageIndex].toLowerCase().replace(" ", "-")}/${vendorId}`);

      fetchVendors();  // Refetch the list to refresh the stage
    } catch (err) {
      console.error("Error updating stage:", err);
    }
  };

  // Reject a vendor
  const rejectVendor = async (vendorId) => {
    try {
      await axios.put(`${config.BASE_URL}/vendors/${vendorId}`, {
        status: "Rejected",
      });

      fetchVendors();
    } catch (err) {
      console.error("Error rejecting vendor:", err);
    }
  };

  // WebSocket and vendor fetching logic
  useEffect(() => {
    fetchVendors();

    // Listen for vendor updates and refresh list dynamically
    socket.on("vendorUpdated", (data) => {
      console.log("Vendor updated in real-time:", data);
      
      // Refresh list only if vendor is no longer pending
      if (data.status !== "Pending") {
        fetchVendors();
      }
    });

    return () => {
      socket.off("vendorUpdated");
    };
  }, []);

  // Filter vendors by search
  const filteredVendors = vendors.filter((vendor) =>
    vendor.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) return <div className="text-center mt-10">Loading...</div>;
  if (error) return <div className="text-center mt-10 text-red-500">{error}</div>;

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      <div className="max-w-7xl mx-auto bg-white rounded-lg shadow-lg">
        {/* Tabs Header */}
        <div className="flex justify-between items-center p-4 border-b bg-gray-100">
          <div className="flex gap-4">
            {stages.map((stage, index) => (
              <button
                key={index}
                onClick={() => setCurrentStage(index)}
                className={`px-4 py-2 rounded ${
                  currentStage === index
                    ? "bg-blue-500 text-white"
                    : "text-gray-600 hover:bg-gray-200"
                }`}
              >
                {stage}
              </button>
            ))}
          </div>
          <div>
            <input
              type="text"
              placeholder="Search vendor..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="px-4 py-2 text-sm border rounded focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        {/* Table */}
        <div className="overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-700">
            <thead className="text-xs text-gray-600 uppercase bg-gray-200">
              <tr>
                <th className="px-6 py-3">#</th>
                <th className="px-6 py-3">Company Name</th>
                <th className="px-6 py-3">Status</th>
                <th className="px-6 py-3">Stage</th>
                <th className="px-6 py-3">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredVendors.map((vendor, index) => (
                <tr key={vendor._id} className="bg-white border-b hover:bg-gray-50">
                  <td className="px-6 py-4">{index + 1}</td>
                  <td className="px-6 py-4 font-semibold">{vendor.name}</td>
                  <td className="px-6 py-4">
                    <span
                      className={`px-2 py-1 rounded-full text-xs font-medium ${
                        vendor.status === "Rejected"
                          ? "bg-red-100 text-red-600"
                          : "bg-green-100 text-green-600"
                      }`}
                    >
                      {vendor.status || "Pending"}
                    </span>
                  </td>
                  <td className="px-6 py-4">{vendor.stage}</td>
                  <td className="px-6 py-4 flex gap-3">
  {(localStorage.getItem("userRole") === "admin" || localStorage.getItem("userRole") === "superadmin") ? (
    <>
      <button
        onClick={() => rejectVendor(vendor._id)}
        className="text-red-600 bg-red-100 px-3 py-1 rounded hover:bg-red-200"
      >
        Reject
      </button>
      <button
        onClick={() => moveToNextStage(vendor._id, vendor.stage)}
        className="text-blue-600 bg-blue-100 px-3 py-1 rounded hover:bg-blue-200"
      >
        Next Stage
      </button>
    </>
  ) : (
    <span className="text-gray-500">Restricted</span>
  )}
</td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Footer */}
        <div className="p-4 bg-gray-100 flex justify-between items-center">
          <span className="text-sm text-gray-500">
            {filteredVendors.length} companies pending approval
          </span>
          <button
            onClick={handleRefresh}
            className="text-blue-600 bg-blue-100 px-4 py-2 rounded hover:bg-blue-200"
          >
            Refresh
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegisterVendor;
