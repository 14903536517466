import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import Footer from "./components/Footer";
import VendorForm from "./components/VendorForm";
import Header from "./components/Header";
import PrivacyPolicy from "./components/PrivacyPolicy";
import VendorLogin from "./components/vendorDashboard/VendorLogin";
import LoginPage from "./components/dashboard/LoginPage";
import Sidebar from "./components/dashboard/Sidebar";
import Dashboard from "./components/pages/Dashboard";
import ManageVendor from "./components/dashboard/ManageVendor";
import Reports from "./components/dashboard/Reports";
import UserManagement from "./components/dashboard/User";
import CreateUser from "./components/dashboard/CreateUser";
import DashboardHeader from "./components/dashboard/Header";
import UserDashboard from "./components/dashboard/UserDashboard";
import PerformanceEvaluationPage from "./components/dashboard/VendorRecords";
import DataTable from "./components/dashboard/DataTable";
import RegisterActionPage from "./components/dashboard/Registervendor";
import Application from "./components/dashboard/Application";
import FinalReview from "./components/dashboard/FinalReview";
import VendorDetailView from "./components/dashboard/VendorDetailView";
import VendorDashboard from "./components/vendorDashboard/VendorDashboard";


const App = () => {
  const location = useLocation();
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(true);

  // Function to get the user's role
  const getUserRole = () => {
    return localStorage.getItem("userRole") || "guest";
  };

  // Helper components for route guards
  const PrivateRoute = ({ children }) => {
    const userRole = getUserRole();
    return userRole !== "guest" ? children : <Navigate to="/login" replace />;
  };

  const ProtectedRoute = ({ children }) => {
    const userRole = getUserRole();
    return userRole === "vendor" ? children : <Navigate to="/vendor-dashboard/login" replace />;
  };

  // Define routes explicitly requiring a sidebar
  const sidebarRoutes = [
    "/dashboard",
    "/manage-vendors",
    "/reports",
    "/user-management",
    "/create-user",
    "/user-dashboard",
    "/performance-evaluation",
    "/data-table",
    "/register-action",
    "/application",
    "/final-review",
    "/vendors",
  ];

  // Check if the current route has a sidebar
  const hasSidebar = sidebarRoutes.some((route) =>
    location.pathname.startsWith(route)
  );

  // Public routes (exclude Sidebar and Header)
  const isPublicRoute = ["/", "/login", "/vendor-dashboard/login", "/update-password"].includes(location.pathname);

  const handlePrivacyAction = (action) => {
    const timestamp = new Date().toISOString();
    console.log(`Action: ${action}, Timestamp: ${timestamp}`);
    setShowPrivacyPolicy(false);
  };

  return (
    <div className="App flex">
      {/* Render Sidebar only for routes requiring it */}
      {hasSidebar && (
        <Sidebar className="sidebar fixed top-0 left-0 h-full w-56 bg-white shadow-md z-10" />
      )}

      <div
        className={
          hasSidebar ? "main-content flex-grow ml-56" : "flex-grow"
        }
      >
        {/* Render DashboardHeader only for routes requiring it */}
        {hasSidebar && <DashboardHeader />}

        <Routes>
          {/* Public Routes */}
          <Route
            path="/"
            element={
              <>
                <Header />
                <main className="container mx-auto p-4 max-w-4xl">
                  <VendorForm />
                </main>
                <Footer />
              </>
            }
          />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/vendor-dashboard/login" element={<VendorLogin />} />

          {/* Private Routes */}
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/manage-vendors"
            element={
              <PrivateRoute>
                <ManageVendor />
              </PrivateRoute>
            }
          />
          <Route
            path="/reports"
            element={
              <PrivateRoute>
                <Reports />
              </PrivateRoute>
            }
          />
          <Route
            path="/user-management"
            element={
              <PrivateRoute>
                <UserManagement />
              </PrivateRoute>
            }
          />
          <Route
            path="/create-user"
            element={
              <PrivateRoute>
                <CreateUser />
              </PrivateRoute>
            }
          />
          <Route
            path="/user-dashboard"
            element={
              <PrivateRoute>
                <UserDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/performance-evaluation"
            element={
              <PrivateRoute>
                <PerformanceEvaluationPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/data-table"
            element={
              <PrivateRoute>
                <DataTable />
              </PrivateRoute>
            }
          />
          <Route
            path="/register-action"
            element={
              <PrivateRoute>
                <RegisterActionPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/application/:vendorID"
            element={
              <PrivateRoute>
                <Application />
              </PrivateRoute>
            }
          />
          <Route
            path="/final-review/:vendorID"
            element={
              <PrivateRoute>
                <FinalReview />
              </PrivateRoute>
            }
          />
          <Route
            path="/vendors/:vendorID"
            element={
              <PrivateRoute>
                <VendorDetailView />
              </PrivateRoute>
            }
          />

          {/* Protected Routes */}
          <Route
            path="/vendor-dashboard/home"
            element={
              <ProtectedRoute>
                <VendorDashboard />
              </ProtectedRoute>
            }
          />
    
           
        </Routes>
      </div>

      {showPrivacyPolicy && (
        <PrivacyPolicy onAction={(action) => handlePrivacyAction(action)} />
      )}
    </div>
  );
};

export default () => (
  <Router>
    <App />
  </Router>
);
  