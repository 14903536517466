import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import { Link } from "react-router-dom";
import DashboardHeader from "./Header";

const Application = () => {
  const { vendorID } = useParams();
  const [vendor, setVendor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [overallRemarks, setOverallRemarks] = useState("");
  const [certificateRemarks, setCertificateRemarks] = useState({});

  const stages = [
    "Application",
    "Application Review",
    "Final Review",
  ];
  const [currentStage, setCurrentStage] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVendor = async () => {
      if (!vendorID) {
        setError("Vendor ID is missing.");
        setLoading(false);
        return;
      }
      try {
        const response = await axios.get(`${config.BASE_URL}/vendors/${vendorID}`);
        setVendor(response.data);
        initializeRemarks(response.data);  // Initialize remarks from the vendor data
      } catch (err) {
        setError(err.response?.data?.error || "Failed to fetch vendor details.");
      } finally {
        setLoading(false);
      }
    };

    fetchVendor();
  }, [vendorID]);

  // Initialize remarks if vendor has remarks already
  const initializeRemarks = (vendorData) => {
    const initialRemarks = {};
    if (vendorData) {
      if (vendorData.remarks) setOverallRemarks(vendorData.remarks);
      if (vendorData.certificates) {
        Object.keys(vendorData.certificates).forEach((key) => {
          initialRemarks[key] = vendorData.certificates[key].remarks || "";
        });
      }
      setCertificateRemarks(initialRemarks);
    }
  };

  // Handle individual certificate remarks updates
  const handleCertificateRemarkChange = (certificateType, value) => {
    setCertificateRemarks((prev) => ({
      ...prev,
      [certificateType]: value,
    }));
  };

  // Save all remarks (Overall + Certificates)
  const handleSaveRemarks = async () => {
    if (!vendor || !vendor._id) {
      alert("Vendor ID is missing. Cannot save remarks.");
      return;
    }

    const payload = {
      overallRemarks,
      certificateRemarks,
    };

    try {
      const response = await axios.put(
        `${config.BASE_URL}/remarks/${vendor._id}/remarks`,
        payload
      );

      if (response.status === 200) {
        alert("Remarks saved successfully!");
        setVendor((prevVendor) => ({
          ...prevVendor,
          remarks: response.data.vendor.remarks,
        }));
      } else {
        throw new Error("Unexpected response from the server.");
      }
    } catch (err) {
      console.error("Failed to save remarks:", err);
      alert("Failed to save remarks.");
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="min-h-screen p-6 bg-gray-100 flex flex-col">
      {/* Dashboard Header */}
      <DashboardHeader />

      {/* Stage Header */}
      <div className="flex justify-between items-center mb-6 p-4 bg-white shadow rounded-lg">
          <div className="flex gap-2">
          {stages.map((stage, index) => (
            <button
              key={index}
              onClick={() => setCurrentStage(index)}
              className={`px-4 py-2 rounded ${
                currentStage === index
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-600 hover:bg-gray-300"
              }`}
            >
              {stage}
            </button>
          ))}
        </div>
      </div>

      {/* Vendor Details Section */}
      <div className="container max-w-screen-lg mx-auto">
        <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
          <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
            <div className="text-gray-600">
              <p className="font-medium text-lg">Vendor Details</p>
              <p>Verify the information provided below.</p>
            </div>

            {/* Vendor Details Grid */}
            <div className="lg:col-span-2">
              <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                {/* Add all fields here */}
                <div className="md:col-span-5">
      <label htmlFor="vendorID">Vendor ID</label>
      <input type="text" name="vendorID" id="vendorID" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={vendor.vendorID || ""} readOnly />
    </div>
    <div className="md:col-span-5">
      <label htmlFor="name">Name</label>
      <input type="text" name="name" id="name" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={vendor.name || ""} readOnly />
    </div>
    <div className="md:col-span-2">
      <label htmlFor="phone_extension">Phone Extension</label>
      <input type="text" name="phone_extension" id="phone_extension" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={vendor.phone_extension || ""} readOnly />
    </div>
    <div className="md:col-span-3">
      <label htmlFor="phone">Phone</label>
      <input type="text" name="phone" id="phone" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={vendor.phone || ""} readOnly />
    </div>
    <div className="md:col-span-5">
      <label htmlFor="email">Email</label>
      <input type="email" name="email" id="email" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={vendor.email || ""} readOnly />
    </div>
    <div className="md:col-span-5">
      <label htmlFor="vendor_type">Vendor Type</label>
      <input type="text" name="vendor_type" id="vendor_type" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={vendor.vendor_type || ""} readOnly />
    </div>

    {/* Local Vendor Information */}
    {vendor.vendor_type === "local" && (
      <>
        <div className="md:col-span-5">
          <label htmlFor="local_vendor_name">Local Vendor Name</label>
          <input type="text" name="local_vendor_name" id="local_vendor_name" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={vendor.local_vendor_name || ""} readOnly />
        </div>
        <div className="md:col-span-5">
          <label htmlFor="customer_account">Customer Account</label>
          <input type="text" name="customer_account" id="customer_account" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={vendor.customer_account || ""} readOnly />
        </div>
        <div className="md:col-span-5">
          <label htmlFor="short_address">Short Address</label>
          <input type="text" name="short_address" id="short_address" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={vendor.short_address || ""} readOnly />
        </div>
      </>
    )}

    {/* Address Details */}
    <div className="md:col-span-3">
      <label htmlFor="building_no">Building Number</label>
      <input type="text" name="building_no" id="building_no" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={vendor.building_no || ""} readOnly />
    </div>
    <div className="md:col-span-2">
      <label htmlFor="street_secondary_number">Street Secondary Number</label>
      <input type="text" name="street_secondary_number" id="street_secondary_number" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={vendor.street_secondary_number || ""} readOnly />
    </div>
    <div className="md:col-span-5">
      <label htmlFor="district">District</label>
      <input type="text" name="district" id="district" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={vendor.district || ""} readOnly />
    </div>
    <div className="md:col-span-3">
      <label htmlFor="address_line_1">Address Line 1</label>
      <input type="text" name="address_line_1" id="address_line_1" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={vendor.address_line_1 || ""} readOnly />
    </div>
    <div className="md:col-span-2">
      <label htmlFor="address_line_2">Address Line 2</label>
      <input type="text" name="address_line_2" id="address_line_2" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={vendor.address_line_2 || ""} readOnly />
    </div>
    <div className="md:col-span-2">
      <label htmlFor="city">City</label>
      <input type="text" name="city" id="city" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={vendor.city || ""} readOnly />
    </div>
    <div className="md:col-span-2">
      <label htmlFor="state">State</label>
      <input type="text" name="state" id="state" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={vendor.state || ""} readOnly />
    </div>
    <div className="md:col-span-1">
      <label htmlFor="postcode">Postcode</label>
      <input type="text" name="postcode" id="postcode" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={vendor.postcode || ""} readOnly />
    </div>
    <div className="md:col-span-2">
      <label htmlFor="country">Country</label>
      <input type="text" name="country" id="country" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={vendor.country || ""} readOnly />
    </div>

    <div className="md:col-span-5">
  <label htmlFor="website">Website</label>
  <input
    type="text"
    name="website"
    id="website"
    className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
    value={vendor.website || ""}
    readOnly
  />
</div>

<div className="md:col-span-2">
  <label htmlFor="fax_extension">Fax Extension</label>
  <input
    type="text"
    name="fax_extension"
    id="fax_extension"
    className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
    value={vendor.fax_extension || ""}
    readOnly
  />
</div>

<div className="md:col-span-3">
  <label htmlFor="fax">Fax</label>
  <input
    type="text"
    name="fax"
    id="fax"
    className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
    value={vendor.fax || ""}
    readOnly
  />
</div>



    {/* Contact & Registration Details */}
    <div className="md:col-span-3">
      <label htmlFor="register_name">Registerer Name</label>
      <input type="text" name="register_name" id="register_name" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={vendor.contactable_person_name || ""} readOnly />
    </div>
    <div className="md:col-span-2">
      <label htmlFor="register_email">Registerer Email</label>
      <input type="email" name="register_email" id="register_email" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={vendor.register_email || ""} readOnly />
    </div>
    <div className="md:col-span-3">
      <label htmlFor="cr_number">CR Number</label>
      <input type="text" name="cr_number" id="cr_number" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={vendor.cr_number || ""} readOnly />
    </div>
    <div className="md:col-span-2">
      <label htmlFor="supplier_type">Supplier Type</label>
      <input type="text" name="supplier_type" id="supplier_type" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={vendor.supplier_type || ""} readOnly />
    </div>

    <div className="md:col-span-5">
  <label htmlFor="classification_type">Classification Type</label>
  <input
    type="text"
    name="classification_type"
    id="classification_type"
    className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
    value={vendor.classification_type || ""}
    readOnly
  />
</div>

<div className="md:col-span-5">
  <label htmlFor="taxpayer_id">Taxpayer ID</label>
  <input
    type="text"
    name="taxpayer_id"
    id="taxpayer_id"
    className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
    value={vendor.taxpayer_id || ""}
    readOnly
  />
</div>

<div className="md:col-span-5">
  <label htmlFor="tax_country">Tax Country</label>
  <input
    type="text"
    name="tax_country"
    id="tax_country"
    className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
    value={vendor.tax_country || ""}
    readOnly
  />
</div>

<div className="md:col-span-5">
  <label htmlFor="vat_number">VAT Number</label>
  <input
    type="text"
    name="vat_number"
    id="vat_number"
    className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
    value={vendor.vat_number || ""}
    readOnly
  />
</div>

<div className="md:col-span-5">
  <label htmlFor="bank_name">Bank Name</label>
  <input
    type="text"
    name="bank_name"
    id="bank_name"
    className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
    value={vendor.bank_name || ""}
    readOnly
  />
</div>

<div className="md:col-span-5">
  <label htmlFor="account_name">Account Name</label>
  <input
    type="text"
    name="account_name"
    id="account_name"
    className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
    value={vendor.account_name || ""}
    readOnly
  />
</div>

<div className="md:col-span-5">
  <label htmlFor="account_number">Account Number</label>
  <input
    type="text"
    name="account_number"
    id="account_number"
    className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
    value={vendor.account_number || ""}
    readOnly
  />
</div>

<div className="md:col-span-5">
  <label htmlFor="branch">Branch</label>
  <input
    type="text"
    name="branch"
    id="branch"
    className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
    value={vendor.branch || ""}
    readOnly
  />
</div>

<div className="md:col-span-5">
  <label htmlFor="iban">IBAN</label>
  <input
    type="text"
    name="iban"
    id="iban"
    className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
    value={vendor.iban || ""}
    readOnly
  />
</div>

<div className="md:col-span-5">
  <label htmlFor="currency">Currency</label>
  <input
    type="text"
    name="currency"
    id="currency"
    className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
    value={vendor.currency || ""}
    readOnly
  />
</div>
<div className="md:col-span-5 mt-6 bg-white p-6 rounded-lg shadow-md">
  <h3 className="font-semibold text-xl text-gray-800 mb-4 border-b pb-2">Certificates</h3>
  <ul className="space-y-6">
    {[
      { key: 'vat_certificate', label: 'VAT Certificate' },
      { key: 'coc_certificate', label: 'COC Certificate' },
      { key: 'zakat_certificate', label: 'Zakat Certificate' },
      { key: 'iban_certificate', label: 'IBAN Certificate' },
      { key: 'national_address_certificate', label: 'National Address Certificate' },
      { key: 'company_profile', label: 'Company Profile' },
      { key: 'pre_qualification', label: 'Pre-Qualification' }
    ].map((cert) => (
      vendor[cert.key] && (
        <li key={cert.key} className="flex flex-col gap-2">
          <div className="flex items-center justify-between">
            <span className="text-gray-700 font-medium">{cert.label}:</span>
            <a
              href={`${config.BASE_URL}/vendors/download/${vendor[cert.key]}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-blue-600 font-semibold hover:underline"
            >
              View & Download
            </a>
          </div>
          <textarea
            placeholder={`Add remarks for ${cert.label}`}
            className="border border-gray-300 rounded-lg p-2 w-full text-sm focus:ring-blue-500 focus:border-blue-500"
            value={certificateRemarks[cert.key] || ""}  // Reflects remark state
            onChange={(e) => handleCertificateRemarkChange(cert.key, e.target.value)}  // Updates specific certificate remark
          />
        </li>
      )
    ))}
  </ul>
</div>


{/* Overall Remarks Section */}
<div className="md:col-span-5 mt-6">
  <label
    htmlFor="overall-remarks"
    className="block mb-2 text-sm font-medium text-gray-900"
  >
    Overall Remarks
  </label>
  <textarea
    id="overall-remarks"
    rows="6"
    className="block p-2.5 w-full text-sm bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
    placeholder="Leave overall remarks about the vendor..."
    value={overallRemarks}
    onChange={(e) => setOverallRemarks(e.target.value)}
  />
</div>

<div className="mt-6 flex justify-end">
  <button
    onClick={handleSaveRemarks}
    className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-700"
  >
    Save Remarks
  </button>
</div>





                {/* Add all the remaining fields following the same structure */}
              </div>
            </div>
          </div>

          {/* Navigation Buttons */}
          <div className="md:col-span-5 flex justify-between items-center mt-4">
  {/* Back Button */}
  <Link
    to="/register-action"
    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
  >
    Back
  </Link>

  {/* Next Button */}
  <Link
  to={vendor && vendor._id ? `/final-review/${vendor._id}` : "#"}
  className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${!vendor?._id && "opacity-50 cursor-not-allowed"}`}
>
  Next Stage: Final Review
</Link>

</div>

        </div>
      </div>
    </div>
  );
};

export default Application;
