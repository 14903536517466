import React, { useEffect, useState, useRef } from "react";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ReactFlow, { MiniMap, Controls, Background } from "react-flow-renderer";
import { format, startOfMonth, endOfMonth } from "date-fns";
import { CSVLink } from "react-csv";
import config from "../../config";

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const MonthlySupplierClassificationReport = () => {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [vendorCountsThisMonth, setVendorCountsThisMonth] = useState(0);
  const [supplierCounts, setSupplierCounts] = useState({});
  const [classificationCounts, setClassificationCounts] = useState({});
  const [vendorData, setVendorData] = useState([]); // Store full vendor details for CSV export
  const [suppliersThisMonth, setSuppliersThisMonth] = useState([]);
  const [classificationsThisMonth, setClassificationsThisMonth] = useState([]);
  const [privacyPolicyActions, setPrivacyPolicyActions] = useState([]); // Privacy Policy Actions data
  const [currentPage, setCurrentPage] = useState(1); // Pagination state
  const [actionsPerPage] = useState(10); // Actions per page
  const [startDate, setStartDate] = useState(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState(endOfMonth(new Date()));
  const chartRef = useRef();

  const generateRandomColor = () => {
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);
    return `rgba(${r}, ${g}, ${b}, 0.8)`;
  };

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const response = await axios.get(`${config.BASE_URL}/vendors`);
        const vendorsData = response.data;

        const filteredVendors = vendorsData.filter((vendor) => {
          const createdAt = new Date(vendor.createdAt);
          return createdAt >= startDate && createdAt <= endDate;
        });

        setVendorData(filteredVendors); // Save full vendor data for CSV export

        const currentMonth = new Date().toLocaleString("default", {
          month: "long",
          year: "numeric",
        });
        const vendorsThisMonthCount = filteredVendors.filter((vendor) => {
          const createdAt = new Date(vendor.createdAt).toLocaleString(
            "default",
            {
              month: "long",
              year: "numeric",
            }
          );
          return createdAt === currentMonth;
        }).length;

        setVendorCountsThisMonth(vendorsThisMonthCount);

        const supplierCounts = {};
        const classificationCounts = {};
        const suppliersThisMonth = new Set();
        const classificationsThisMonth = new Set();

        filteredVendors.forEach((vendor) => {
          if (vendor.supplier_type) {
            supplierCounts[vendor.supplier_type] =
              (supplierCounts[vendor.supplier_type] || 0) + 1;
            if (
              new Date(vendor.createdAt) >= startOfMonth(new Date()) &&
              new Date(vendor.createdAt) <= endOfMonth(new Date())
            ) {
              suppliersThisMonth.add(vendor.supplier_type);
            }
          }
          if (vendor.classification_type) {
            classificationCounts[vendor.classification_type] =
              (classificationCounts[vendor.classification_type] || 0) + 1;
            if (
              new Date(vendor.createdAt) >= startOfMonth(new Date()) &&
              new Date(vendor.createdAt) <= endOfMonth(new Date())
            ) {
              classificationsThisMonth.add(vendor.classification_type);
            }
          }
        });

        const supplierColors = Object.keys(supplierCounts).map(() =>
          generateRandomColor()
        );
        const classificationColors = Object.keys(classificationCounts).map(() =>
          generateRandomColor()
        );

        setChartData({
          labels: [
            "Vendors This Month",
            ...Object.keys(supplierCounts),
            ...Object.keys(classificationCounts),
          ],
          datasets: [
            {
              label: "Vendors This Month",
              data: [
                vendorsThisMonthCount,
                ...new Array(
                  Object.keys(supplierCounts).length +
                    Object.keys(classificationCounts).length
                ).fill(0),
              ],
              backgroundColor: "#741017",
              barThickness: 40,
              maxBarThickness: 60,
            },
            {
              label: "Supplier Types",
              data: [
                0,
                ...Object.values(supplierCounts),
                ...new Array(Object.keys(classificationCounts).length).fill(0),
              ],
              backgroundColor: supplierColors,
              barThickness: 40,
              maxBarThickness: 60,
            },
            {
              label: "Classification Types",
              data: [
                0,
                ...new Array(Object.keys(supplierCounts).length).fill(0),
                ...Object.values(classificationCounts),
              ],
              backgroundColor: classificationColors,
              barThickness: 40,
              maxBarThickness: 60,
            },
          ],
        });

        setSupplierCounts(supplierCounts);
        setClassificationCounts(classificationCounts);
        setSuppliersThisMonth(Array.from(suppliersThisMonth));
        setClassificationsThisMonth(Array.from(classificationsThisMonth));
      } catch (error) {
        console.error("Error fetching vendors:", error);
      }
    };

    

    const fetchPrivacyPolicyActions = async () => {
      try {
        const response = await axios.get(
          `${config.BASE_URL}/privacy-policy/logs`
        );
        setPrivacyPolicyActions(response.data);
      } catch (error) {
        console.error("Error fetching privacy policy actions:", error);
      }
    };

    fetchVendors();
    fetchPrivacyPolicyActions();
  }, [startDate, endDate]);

  const downloadChart = async () => {
    const canvas = await html2canvas(chartRef.current);
    const dataURL = canvas.toDataURL("image/png");
    const pdf = new jsPDF();
    pdf.addImage(dataURL, "PNG", 0, 0, 210, 100);
    pdf.save("chart.pdf");
  };

  // Pagination Logic
  const indexOfLastAction = currentPage * actionsPerPage;
  const indexOfFirstAction = indexOfLastAction - actionsPerPage;
  const currentActions = privacyPolicyActions.slice(
    indexOfFirstAction,
    indexOfLastAction
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const flowchartNodes = [
    {
      id: "1",
      data: { label: `Vendors This Month: ${vendorCountsThisMonth}` },
      position: { x: 0, y: 0 },
    },
    {
      id: "2",
      data: { label: `Suppliers Count: ${Object.keys(supplierCounts).length}` },
      position: { x: 0, y: 100 },
    },
    {
      id: "3",
      data: {
        label: `Classifications Count: ${
          Object.keys(classificationCounts).length
        }`,
      },
      position: { x: 0, y: 200 },
    },
    {
      id: "4",
      data: { label: "Process Vendors" },
      position: { x: 200, y: 100 },
    },
  ];

  const flowchartEdges = [
    {
      id: "e1-4",
      source: "1",
      target: "4",
      animated: true,
      label: "Processed",
    },
    {
      id: "e2-4",
      source: "2",
      target: "4",
      animated: true,
      label: "Approved",
    },
    {
      id: "e3-4",
      source: "3",
      target: "4",
      animated: true,
      label: "Approved",
    },
  ];

  // Data for CSV export with supplier and classification details
  const supplierCSVData = vendorData.map((vendor) => ({
    Company: vendor.name,
    Supplier_Type: vendor.supplier_type,
    Classification_Type: vendor.classification_type,
    Created_At: vendor.createdAt,
  }));

  const classificationCSVData = vendorData.map((vendor) => ({
    Company: vendor.name,
    Classification_Type: vendor.classification_type,
    Supplier_Type: vendor.supplier_type,
    Created_At: vendor.createdAt,
  }));

  return (
    <div className="flex justify-center items-center w-full bg-white rounded-lg shadow dark:bg-gray-800 p-6">
      <div className="max-w-4xl w-full">
        {/* Header Section */}
        <h5 className="leading-none text-3xl font-bold text-[#741017] pb-2">
          Monthly Report
        </h5>
        <p className="text-base font-normal text-gray-500 dark:text-gray-400">
          Vendors Submitted Month-wise
        </p>

        {/* Display Privacy Policy Actions Summary in Header */}
        <div className="mt-4">
          <h6 className="text-lg font-semibold text-[#741017]">
            Privacy Policy Actions
          </h6>
          <p className="text-sm text-gray-500 dark:text-gray-400">
            Total Actions: {privacyPolicyActions.length}
          </p>
        </div>

        {/* Date Range Picker */}
        <div id="date-range-picker" className="flex items-center mb-4 mt-4">
          <div className="relative">
            <input
              id="datepicker-range-start"
              type="date"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#741017] focus:border-[#741017] block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              value={format(startDate, "yyyy-MM-dd")}
              onChange={(e) => setStartDate(new Date(e.target.value))}
            />
          </div>
          <span className="mx-4 text-gray-500">to</span>
          <div className="relative">
            <input
              id="datepicker-range-end"
              type="date"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#741017] focus:border-[#741017] block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              value={format(endDate, "yyyy-MM-dd")}
              onChange={(e) => setEndDate(new Date(e.target.value))}
            />
          </div>
        </div>

        {/* Chart Section */}
        <div className="bg-white border border-gray-100 shadow-md shadow-black/5 p-6 rounded-md lg:col-span-2">
  <div className="flex justify-between mb-4 items-start">
    <div className="font-medium">Vendors, Supplier Types, and Classification Types</div>
  </div>
  <div>
    <div
      ref={chartRef}
      style={{ overflowX: 'scroll', width: '100%', height: '500px' }}
    >
      <div style={{ width: '1200px' }}>
        <Bar
          data={chartData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: { position: 'top' },
              title: {
                display: true,
              },
            },
            scales: {
              x: { beginAtZero: true, ticks: { autoSkip: false } },
              y: { beginAtZero: true },
            },
          }}
          height={400}
        />
      </div>
    </div>
  </div>
</div>

       {/* Download and Export Buttons */}
<div className="grid grid-cols-1 items-center border-gray-200 border-t dark:border-gray-700 justify-between mt-5">
  <div className="flex justify-between items-center pt-5">
    <button
      onClick={downloadChart}
      className="relative overflow-hidden h-12 px-8 rounded-full bg-gray-800 text-white flex items-center justify-center cursor-pointer hover:before:scale-x-100 before:absolute before:top-0 before:left-0 before:w-full before:h-full before:scale-x-0 before:origin-left before:rounded-full before:bg-[#741017] before:transition-transform before:duration-300 before:ease-in-out"
    >
      <span className="relative z-10">Download Chart</span>
    </button>
    <CSVLink
      data={supplierCSVData}
      filename="suppliers.csv"
      className="ml-4 relative overflow-hidden h-12 px-8 rounded-full bg-gray-800 text-white flex items-center justify-center cursor-pointer hover:before:scale-x-100 before:absolute before:top-0 before:left-0 before:w-full before:h-full before:scale-x-0 before:origin-left before:rounded-full before:bg-[#741017] before:transition-transform before:duration-300 before:ease-in-out"
    >
      <span className="relative z-10">Export Suppliers as CSV</span>
    </CSVLink>
    <CSVLink
      data={classificationCSVData}
      filename="classifications.csv"
      className="ml-4 relative overflow-hidden h-12 px-8 rounded-full bg-gray-800 text-white flex items-center justify-center cursor-pointer hover:before:scale-x-100 before:absolute before:top-0 before:left-0 before:w-full before:h-full before:scale-x-0 before:origin-left before:rounded-full before:bg-[#741017] before:transition-transform before:duration-300 before:ease-in-out"
    >
      <span className="relative z-10">Export Classifications as CSV</span>
    </CSVLink>
  </div>
</div>


        {/* Flowchart Section */}
        <div className="py-6">
          <div style={{ width: "100%", height: "400px" }}>
            <ReactFlow nodes={flowchartNodes} edges={flowchartEdges} fitView>
              <MiniMap />
              <Controls />
              <Background />
            </ReactFlow>
          </div>
        </div>

        {/* Supplier Counts */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
          {Object.keys(supplierCounts).map((type, index) => (
            <div key={index} className="bg-white p-6 shadow rounded-lg">
              <h2 className="text-lg font-semibold text-[#741017]">{type}</h2>
              <p className="text-4xl font-bold text-[#741017]">
                {supplierCounts[type]}
              </p>
              {suppliersThisMonth.includes(type) && (
                <span className="bg-[#741017]/20 text-[#741017] text-xs font-medium px-2.5 py-0.5 rounded mt-2">
                  Added This Month
                </span>
              )}
              <p className="text-sm text-gray-500">Vendors</p>
            </div>
          ))}
        </div>

        {/* Classification Counts */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
          {Object.keys(classificationCounts).map((type, index) => (
            <div key={index} className="bg-white p-6 shadow rounded-lg">
              <h2 className="text-lg font-semibold text-[#741017]">{type}</h2>
              <p className="text-4xl font-bold text-[#741017]">
                {classificationCounts[type]}
              </p>
              {classificationsThisMonth.includes(type) && (
                <span className="bg-green-200 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded mt-2">
                  Added This Month
                </span>
              )}
              <p className="text-sm text-gray-500">Vendors</p>
            </div>
          ))}
        </div>

        {/* Privacy Policy Actions Table */}
        <div className="py-6">
          <h5 className="leading-none text-xl font-bold text-[#741017] pb-2">
            Privacy Policy Actions
          </h5>

          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Domain
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Action
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Timestamp
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentActions.length === 0 ? (
                  <tr>
                    <td
                      colSpan="3"
                      className="px-6 py-4 text-center text-gray-500"
                    >
                      No actions found.
                    </td>
                  </tr>
                ) : (
                  currentActions.map((action, index) => (
                    <tr
                      key={index}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    >
                      <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {action.domainName || "N/A"}
                      </td>
                      <td className="px-6 py-4">{action.action}</td>
                      <td className="px-6 py-4">
                        {new Date(action.timestamp).toLocaleString()}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          <nav
            aria-label="Table Pagination"
            className="mt-4 flex items-center justify-center"
          >
            <ul className="flex items-center -space-x-px h-8 text-sm">
              <li>
                <button
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                  className={`flex items-center justify-center px-3 h-8 leading-tight ${
                    currentPage === 1
                      ? "text-gray-400 bg-gray-200 cursor-not-allowed"
                      : "text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700"
                  } border border-gray-300 rounded-l-lg`}
                >
                  <span className="sr-only">Previous</span>
                  <svg
                    className="w-2.5 h-2.5 rtl:rotate-180"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 1 1 5l4 4"
                    />
                  </svg>
                </button>
              </li>
              {Array.from(
                {
                  length: Math.ceil(
                    privacyPolicyActions.length / actionsPerPage
                  ),
                },
                (_, i) => (
                  <li key={i}>
                    <button
                      onClick={() => paginate(i + 1)}
                      className={`flex items-center justify-center px-3 h-8 leading-tight ${
                        currentPage === i + 1
                          ? "text-blue-600 bg-blue-50 border border-blue-300"
                          : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                      }`}
                    >
                      {i + 1}
                    </button>
                  </li>
                )
              )}
              <li>
                <button
                  onClick={() => paginate(currentPage + 1)}
                  disabled={
                    currentPage ===
                    Math.ceil(privacyPolicyActions.length / actionsPerPage)
                  }
                  className={`flex items-center justify-center px-3 h-8 leading-tight ${
                    currentPage ===
                    Math.ceil(privacyPolicyActions.length / actionsPerPage)
                      ? "text-gray-400 bg-gray-200 cursor-not-allowed"
                      : "text-[#741017] bg-white hover:bg-[#741017]/20 hover:text-[#741017]"
                  } border border-gray-300 rounded-r-lg`}
                >
                  <span className="sr-only">Next</span>
                  <svg
                    className="w-2.5 h-2.5 rtl:rotate-180"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke={
                        currentPage ===
                        Math.ceil(privacyPolicyActions.length / actionsPerPage)
                          ? "gray"
                          : "#741017"
                      }
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 9 4-4-4-4"
                    />
                  </svg>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default MonthlySupplierClassificationReport;
